@import "Variable";

.service-plan-content{


  .carmelo-main-services-wrap{

    margin-top:20px;
    box-shadow: 0 0 40px 0 rgba(51,51,51,.05);
    padding: 30px;

    .tip-box{
      margin-left:-30px;
      padding: 0;
      padding-left:130px;
      box-shadow: none;
    }

  }

  .main-services{

    h3{
      color: #333;
      font-size: 1.3em;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    ul{
      li{
        margin-bottom:5px;
        list-style: none;
      }
    }
  }

  .hours-allocation{

    padding: 10px 0;
    margin-bottom:10px;

    font-weight: bold;
    span{
      color: $primary;
    }
  }


  .max-hours-reached{


    .max-hours-q1{
      margin-bottom: 10px;
    }

    .option{
      position: relative;


      label{
        margin-bottom:3px;
        position: relative;
        padding-left: 30px;
      }

      span{
        left: 0;
        top: 0;
        position: absolute;
        border: 1px solid #dedede;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
        -webkit-transform: translateY(1px);
        -moz-transform: translateY(1px);
        -ms-transform: translateY(1px);
        -o-transform: translateY(1px);
        transform: translateY(1px);
        transition: all .3s ease;


        &.active{
          &:after{
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            line-height: 18px;
            font-size: 10px;
            content: '\f00c';
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $primary;
          }

        }

        &:hover{
          border-color: $primary;
        }

        input{
          position: relative;
          z-index: 10;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .proceed_within_hours{
      border: 0;
      border-bottom: 1px solid #ededed;
      width: 34px;
      outline: none;
      text-align: center;
      padding: 0 3px;
    }
  }

  .questionnaire-info-policies{
    margin-top:20px;
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.05);
    padding: 30px;
    margin-bottom:50px;

    .col-lg-6{
      margin: 20px 0;
    }

    h3{
      color: #333;
      font-size: 1.3em;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    ul{
      margin-left:17px;
    }
  }


  @media (max-width: 991px) {


    .main-services{

      margin-top:30px;
    }
  }
}





.service-plan-content-text{

  max-width: 1000px;
  box-shadow: 0 0 40px 0 rgba(0,0,0,.03);
  padding: 30px;
  margin-bottom: 30px;

  line-height: 20px;

  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  h1,h2,h3,h4{
    margin-bottom:1em;
    font-weight: normal;
  }



  p{
    margin-bottom:20px;
  }
  ul{
    margin-bottom:20px;

    li{
      list-style: none;
      position: relative;
      padding-left: 20px;

      &:before{
        position: absolute;
        left: 0;
        content: '';
        width:6px;
        height: 6px;
        background: $primary;
        border-radius: 50%;
        top:10px;
      }
    }

    ul{
      margin-top:5px;
      margin-bottom:5px;

      li{
        &:before{
          background: #dedede;
        }
      }
    }
  }

  .accordion{

    .card-header{
      background: #FFF;
      padding:6px;

    }
    .card-body{
      padding: 20px 20px 20px;

      strong{
        font-weight: 500;
      }

      p{
        margin-bottom:10px;
      }
    }
    h2{

      button{
        font-size: 14px;
        text-decoration: none;
        color: $primary;
        text-transform: uppercase;

        &:hover{
          color: #333;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0;
    box-shadow: none;
  }
}