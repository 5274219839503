@import "Variable";

header{
    padding-top:15px;
    background: #FFF;

    .container{
        position: relative;
    }

    .header-wrap{
        position: relative;
    }

    .logo{
        display: inline-block;

        img{
            max-width: 140px;
        }
    }

    .client-logo{
        position: absolute;
        width: 200px;
        text-align: center;
        top: 0;
        left:50%;
        margin-left:-100px;

        img{
            max-height: 40px;
        }
    }

    .breadcrumbs{
        z-index: 10;
        position: absolute;
        right:20px;
        width:35px;
        height: 40px;
        top:15px;
        display: none;

        span{
            position: absolute;
            height: 1px;
            top:50%;
            margin:0;
            left: 0;
            right:0;
            background: #999;

            &:before,
            &:after{
                background: #999;
                height: 1px;
                position: absolute;
                content: '';
                margin:0;
                left: 0;
                right:0;
            }

            &:before{
                top: -8px;
            }

            &:after{
                bottom:-8px;
            }
        }
    }

    .logged-user{
        position: absolute;
        right: 0;
        top:4px;
        padding-right: 80px;
        font-size: 13px;
        text-align: right;
        padding-top:10px;
        line-height: normal;
        color: #678492;
        font-weight: 500;

        a{
            color: #06bbe8;
        }

        .photo{
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 50%;
            overflow: hidden;
            width:50px;
            height: 50px;
            border: 1px solid #ededed;
            text-align: center;
            line-height: 48px;

            img{
                max-width: 30px;
                margin-top:-2px;
            }


            span.photo-letter {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                line-height: 48px;
                text-align: center;
                font-size: 3em;
                font-weight: bold;
                color: #d2d2d2;
                transition: all .3s ease;

                .fa-user{
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    font-size: 20px;
                    line-height: 50px;
                }
            }

            &:hover{
                span.photo-letter {
                    color: $primary;
                }
            }
        }

        a.signout-link{
            color: #999999;
        }


    }

    nav{
        display: block;
        margin-top: 15px;
        position: relative;

        ul.main-menu{
            position: relative;
            text-align: center;

            &:before{
                position: absolute;
                top:0;
                bottom: 0;
                left:-10000px;
                right:-10000px;
                border-bottom: 1px solid #eaeeef;
                content: '';
                z-index: 2;
            }

            li{
                position: relative;
                z-index: 10;
                display: inline-block;


                a{
                    color: #c8cbcd;
                    display: inline-block;
                    padding: 15px 0;
                    margin:0 20px;
                    text-decoration: none;
                    transition: all .4s ease;
                    font-weight: 400;

                    &:hover,
                    &.is-active{

                        color: $primary;
                    }
                }


            }

        }

        .btn{
            position: absolute!important;
            bottom: 10px;
            right: 0;
        }

    }


    @media (max-width: 991px) {


        .client-logo{

            img{
                max-height: 35px;
            }

        }
        .breadcrumbs{
            display: block;
        }

        .logged-user{

            display: none;
        }

        nav{


            ul.main-menu{

                //text-align: left;

                li{
                    a{
                        padding-left:0;
                        margin: 0 30px 0 0;
                    }
                }

            }
        }

    }


    @media (max-width: 767px) {

        padding:15px 0;
        border-bottom: 1px solid #ededed;

        .client-logo{
            display: none;

        }
        nav{
            display: none;
        }

    }
}


.mobile-nav{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 320px;
    z-index: 111100;
    visibility: hidden;
    pointer-events: none;
    transition: all .3s ease;
    color: #333;

    a{
        text-decoration: none;
        color: $primary;


    }

    .login-text{
        padding-top:100px;
        padding-bottom: 30px;
        text-align: center;

        a{
            color: $primary;
        }
    }

    .mobile-nav-close{
        width: 30px;
        height: 30px;
        position: absolute;
        right:20px;
        top:20px;
        z-index: 22;



        &:before,
        &:after{
            width:1px;
            height: 30px;
            background: #FFF;
            transition: all .4s ease;
            position: absolute;
            content: '';
            left:50%;
            top: 0;
        }

        &:before{
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:after{
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }


        &:hover{
            &:before,
            &:after{
                background: #ededed;
            }
        }
    }

    .backdrop{
        position: absolute;
        left: -100000px;
        right:0;
        top: 0;
        bottom: 0;
        background: rgba(0,0,0,.2);
        opacity: 0;
    }

    .btn-primary{
        border: 0!important;
        margin-bottom:10px;
        padding: 15px 30px!important;
        display: block;
        height: auto;
    }

    .mobile-nav-wrap{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding:0 20px;
        z-index: 2;
        background: #FFF;
        overflow: hidden;
        transition: all .3s ease;
        -webkit-transform: translateX(320px);
        -moz-transform: translateX(320px);
        -ms-transform: translateX(320px);
        -o-transform: translateX(320px);
        transform: translateX(320px);


        .main-menu{

            a.cur{
                color: $primary;
            }
        }
    }

    .logged-user{
        position: relative;
        padding-left: 85px;
        height: 70px;
        font-size: 14px;
        padding-top:16px;
        line-height: normal;
        margin-left:-20px;
        margin-right:-20px;
        background: $primary;
        color: #FFF;

        a{
            color: #FFF;

            .name{
                color: #FFF;
                font-weight: 600;
            }
        }

        &:after{
            left:-100px;
            right:-100px;
            content: '';
            position: absolute;
            bottom: 0;
        }

        .photo{
            position: absolute;
            left: 0;
            top: 10px;
            border-radius: 50%;
            overflow: hidden;
            width:50px;
            height: 50px;
            border: 1px solid #FFF;
            text-align: center;
            line-height: 45px;
            background: #FFF;
            margin-left: 20px;

            img{
                max-width: 30px;
            }


            span.photo-letter {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                line-height: 43px;
                text-align: center;
                font-size: 3em;
                font-weight: bold;
                color: $primary;
                transition: all .3s ease;

                .fa-user{
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    font-size: 15px;
                    line-height: 50px;
                }
            }

            &:hover{
                span.photo-letter {
                    color: $primary;
                }
            }
        }
    }

    nav{
        padding: 0!important;
    }
    ul.main-menu{
        margin-bottom:20px;
        margin-left:-20px;
        margin-right:-20px;
        margin-top:20px;

        li{
            list-style: none;
            border-bottom: 1px solid #ededed;

            a{
                padding:10px 20px 10px 32px;
                display: block;
                color: #333;
                text-decoration: none;
                transition: all .4s ease;

                i{
                    width:24px;
                    text-align: center;
                    margin-right:10px;
                    color: $primary;
                    transition: all .4s ease;
                }

                &:hover,
                &.is-active{
                    color: $primary;

                    i{

                    }
                }
            }
        }
    }




    &.open{

        visibility: visible;
        pointer-events: inherit;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);

        .mobile-nav-wrap{
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }

        .backdrop{
            opacity: 1;
        }
    }


}






/*

ul.sub-nav{
    position: relative;
    text-align: center;
    display: none;
    background: #FFF;
    box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.02);


    li{
        display: inline-block;
        position: relative;
        z-index: 3;

        a{
            display: block;
            padding: 15px 25px;
            text-decoration: none;
            color: #222a33;
            font-weight: 500;
            transition: all .4s ease;
            font-size: 16px;
            position: relative;

            &:after{
                bottom: 0;
                height: 2px;
                background: $primary;
                position: absolute;
                content: '';
                left: 25px;
                right: 25px;
                visibility: hidden;
                opacity: 0;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                transform: scale(0);
                transition: all .4s ease;
            }

            img{
                display: none;
            }

            &.active,
            &:hover{
                color: $primary;

                &:after{
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                }
            }

            &.active{

            }
        }
    }

    @media (max-width: 600px) {

        margin-top:0;

        li{

            a{
                padding: 0 10px;
                font-size: .8rem;
            }
        }
    }

}


body.client-account-page{
    .page-content{
        max-width: 1000px;
        margin:0 auto;
    }
}


body.client-account-page-xxx{


    @media (min-width: 992px) {

        .container-fluid{
            //max-width:100%;
        }

        ul.sub-nav {
            float: left;
            margin: 0;
            width: 200px;
            text-align: left;

            li {
                display: block;

                a {
                    padding: 3px 0;
                    display: block;
                    position: relative;

                    img {
                        max-width: 14px;
                        margin-right: 5px;
                        display: inline-block;
                        float: left;
                        margin-top: 3px;
                    }

                    &:after {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        left: -10px;
                        top: 12px;
                        content: '';
                        border-radius: 50%;
                        background: #cbcbcb;
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transition: all .4s ease;
                        transition: all .4s ease;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }

                    &.active {
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            transform: scale(1);
                            background: $primary;
                        }
                    }

                    &:hover {
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .page-content {
            margin-left: 240px;
        }

    }
}

*/




ul.sub-nav{
    position: relative;
    text-align: center;
    margin-bottom: 50px;
    display: none;


    li{
        display: inline-block;
        position: relative;
        z-index: 3;

        a{
            display: block;
            padding: 0 25px;
            text-decoration: none;
            color: #222a33;
            font-weight: 500;
            transition: all .4s ease;
            font-size: 16px;

            img{
                display: none;
            }

            &.active,
            &:hover{
                color: $primary;
            }


        }
    }

    @media (max-width: 600px) {

        margin-top:0;
        margin-bottom: 20px;

        li{

            a{
                padding: 0 10px;
                font-size: .8rem;
            }
        }
    }

}





body.client-account-page{


    @media (min-width: 992px) {

        .container-fluid{
            max-width:100%;
        }

        ul.sub-nav {
            float: left;
            margin: 0;
            width: 200px;
            text-align: left;
            position: fixed;
            left:15px;

            li {
                display: block;

                a {
                    padding: 3px 0;
                    display: block;
                    position: relative;
                    font-size: 14px;

                    img {
                        max-width: 14px;
                        margin-right: 8px;
                        display: inline-block;
                        float: left;
                        margin-top: 3px;
                        transition: all .4s ease;
                    }

                    &:after {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        left: -10px;
                        top: 12px;
                        content: '';
                        border-radius: 50%;
                        background: #cbcbcb;
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transition: all .4s ease;
                        transition: all .4s ease;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }

                    &.active {
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            transform: scale(1);
                            background: #009bd7;
                        }
                    }

                    &:hover {

                        img{
                            -webkit-transform: translateX(3px);
                            -moz-transform: translateX(3px);
                            -ms-transform: translateX(3px);
                            -o-transform: translateX(3px);
                            transform: translateX(3px);
                        }
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .page-content {
            margin-left: 240px;
        }

    }
}

