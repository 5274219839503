@import "Variable";


.availability-content{
  overflow: visible;
  //min-height: 800px;

  font-size: 14px;


  .availability-text-intro{
    max-width: 500px;
    text-align: center;
    margin:0 auto;
    padding-bottom: 50px;
  }

  .calendar-guide{
    h3,h4{
      color: #000;
    }

    h3{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &:after{
        height: 2px;
        width: 160px;
        position: absolute;
        content: '';
        background: #ededed;
        bottom: 0;
        left:50%;
        margin-left:-80px;
      }
    }


    h4{
      font-size: 16px;
      padding-left: 20px;
      position: relative;

      &:after{
        width:8px;
        height: 8px;
        border-radius: 50%;
        left:0;
        top:50%;
        margin-top: -4px;
        content: '';
        position: absolute;
        background: $primary;
      }

    }

    .guide{
      margin-top: 30px;
    }

    .cal-guide-1{
      h4{
        &:after{
          background: #458af7;
        }
      }
    }

    .cal-guide-2{
      h4{
        &:after{
          background: #cadffc;
        }
      }
    }

    .cal-guide-3{
      h4{
        &:after{
          background: #d1d0cd;
        }
      }
    }

  }

  .cal-foot-notes{
    margin-top: 100px;
    padding: 0 100px;
    text-align: center;

    .item{
      padding: 0 20px 20px 20px;
    }

    h3{
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      position: relative;
      padding-bottom: 10px;
      color: #000;
    }
  }

  .availability-cal-wrap{
    border-radius: 5px;
    padding: 40px;
    background-color: #FFF;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.05);

    .availability-cal-inner{

      padding-bottom:1px;
    }

    .cal-wrap{
      border: 1px solid #ededed;
      padding-bottom:1px;
      border-top: 0;
    }


  }

  #menu{

    padding: 0 20px 20px;
    text-align: center;
    border-bottom: 0;

    button{
      border: 1px solid #ededed;
      border-radius: 60px;
      display: inline-block;
      padding: 5px 20px;
      background: #FFF;
      margin-right:4px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
      outline: none;
      transition: all .4s ease;

      &:hover{
        color: $primary;
        border-color: $primary;;
      }

      .fa{
        font-size: 10px;
      }
    }

    .render-range{
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.6em;
      margin-top: 0;
      margin-bottom:10px;

      span{
        display: block;
        font-size: 1rem;
        color: #666;
      }
    }

  }




  .tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator{
    background: #0089d2;

  }

  .tui-full-calendar-month-dayname-item{
    border: 0!important;
    text-align: right;
    text-transform: uppercase;

    span{
      color: #999999!important;
    }
  }

  .tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-date{
    font-weight: bold;
  }

  .tui-full-calendar-popup-section{
    min-height: 0!important;
    margin-bottom:10px;
  }


  .tui-full-calendar-popup{
    font-weight: 400;
  }


  .tui-full-calendar-weekday-grid-line{
    background-color: #FFF!important;
  }

  .tui-full-calendar-weekday-grid-line.tui-full-calendar-extra-date{
    background-color: #fafafa!important;
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *
   * Hide Fields in Calendar Popup Form
   *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   */

  .tui-full-calendar-popup-section-item{

    &.tui-full-calendar-section-allday,
    &.tui-full-calendar-section-start-date,
    &.tui-full-calendar-section-end-date,
    &.tui-full-calendar-section-location{
      //display: none!important;
    }

  }

  .tui-full-calendar-popup-section.tui-full-calendar-dropdown.tui-full-calendar-close.tui-full-calendar-section-state{
    //display: none;
  }


  /*************/
}

.tui-full-calendar-month{


  > div:nth-child(2),
  .tui-view-7{
    height: 800px!important;
    background: #fafafa;

    @media (max-width: 600px) {
      height: 400px!important;
    }
  }


}
