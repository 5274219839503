@import "Variable";

body.login-page{

  background: #03ccd9;
  background: -webkit-linear-gradient(left, #03ccd9 0%, #7c81f4 100%);
  background: linear-gradient(to right, #03ccd9 0%, #7c81f4 100%);

}


.login-form{

  max-width: 400px;
  margin:50px auto;
  padding: 0 20px;

  .login-logo{
    padding: 20px;
    margin-bottom: 40px;
    background: #FFF;
    margin-top:-40px;
    margin-left:-40px;
    margin-right:-40px;
    display: block;
    text-align: center;

    img{
      max-width: 150px;
    }
  }

  form{
    overflow: hidden;
    background: rgba(255,255,255,.1);
    padding: 40px;
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.1);

    .btn{
      border: 0!important;
      border-radius: 3px;
      width:100%;
      display: block;
    }

    .form-control{
      border: 0;
    }
  }

  .alert{
    margin-top: 20px;
  }

  a{
    color: #FFF;
  }

  .login-links{
    margin-top:10px;
    font-size: 13px;
  }
}




.logout-content{
  margin-top: 50px;
  font-size: 12px;
}