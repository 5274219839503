@import "Variable";


.draftjs-editor-wrap{
  border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 0 0 40px;
  position: relative;



  .editor-static-toolbar{
    border-bottom: 1px solid #eaeaea;
    position: relative;


    .draftJsToolbar__toolbar__dNtBH{
      left: 0;
      right: 0;
      position: relative;
      -webkit-transform: scale(1)!important;
      -moz-transform: scale(1)!important;
      -ms-transform: scale(1)!important;
      -o-transform: scale(1)!important;
      transform: scale(1)!important;
      box-shadow: none;
      border: 0;

      .draftJsToolbar__button__qi1gf{
        background: none;
      }

      &:before{
        display: none;
      }
    }


    button{
      margin-bottom:0!important;
      padding: 10px!important;
      height: auto!important;;
      width:auto!important;

      &:hover{
        background: #fafafa!important;
      }
    }



  }

  .draftJsToolbar__button__qi1gf svg{
    height: 20px;
  }

  .draftJsToolbar__separator__3U7qt{

    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
  }


  .DraftEditor-editorContainer,
  .public-DraftEditorPlaceholder-root {
    padding: 15px 15px;
    min-height: 120px;
  }


  .DraftEditor-editorContainer .public-DraftEditorPlaceholder-root,
  .DraftEditor-editorContainer .public-DraftEditor-content {
    margin: 0;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content {
    min-height: 100px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }


  .public-DraftEditorPlaceholder-root {
    color: #727c85;
    position: absolute;
    z-index: 0;

    &.public-DraftEditorPlaceholder-hasFocus{
      //color: #4f5259;
    }
  }


  .draftJsMentionPlugin__input__1Wxng{
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #333;


    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #aaa;
    }



  }

  .draftJsMentionPlugin__inputInvalid__X9hHv{
    color: #e65757!important;
  }

  .inline-toolbar .draftJsToolbar__toolbar__dNtBH{
    background: #2c3c44;
    border: 0;
    min-width: 220px;
    text-align: center;

    &:after{
      border-top-color: #283c45;
    }

    &:before{
      border-top-color: #283c45;
    }

    button{
      background: transparent;
      color: #FFF;
      margin-bottom:0!important;

      svg{
        fill: #FFF!important;
      }

      &:hover{
        background: #253239;
      }
    }
  }


  @media (max-width: 767px) {


    .editor-static-toolbar{

      button{
        padding:5px!important;

        svg{
          height: 15px;
        }
      }

      .draftJsToolbar__separator__3U7qt{

        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }


    }
  }


}




.draftJsToolbar__button__qi1gf{
  padding:0!important;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}


.DraftEditor-editorContainer {
  cursor: text;
  font-size: 16px;

  blockquote{
    padding-left: 20px;
    border-left: 4px solid #ededed;
    margin-bottom: 15px;
  }

  pre{

  }

  ul,
  ol{
    margin: 0;
    padding: 0;
    margin-left: 17px;

    li{
      margin-bottom:5px;
    }
  }


}


.request-form-wrap{
  max-width: 700px;

  .request-form{

    p{
      margin-top: 1em;
      color: $primary;
    }
  }

  .attach-file-btn{
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-weight: 500;
    outline: none;
    font-size: .85em;

    i{
      font-size: .7em;
    }

    p{
      margin: 0;
      padding: 0;
      cursor: pointer;
      outline: none;
      color: $primary;
      transition: all .3s ease;
    }

    &:hover{
      p{
        color: #585858;
      }
    }

  }

  .attachments{

    margin-top: 20px;

    .attachment-item{
      margin-bottom: 5px;
      position: relative;
      font-size: 14px;

      span{
        display: inline-block;
        color: #dedede;
        margin-right: 10px;
        font-weight: bold;
        background: #fafafa;
        text-align: center;
        width:20px;
        height: 20px;
        line-height: 17px;
        font-size: 12px;
        border: 1px solid #ededed;
        border-radius: 50%;
        cursor: pointer;
        transition: all .4s ease;


        &:hover{
          background: red;
          border-color: red;
          color: #FFF;
        }
      }
    }
  }


  .form-notes{

    margin:0 auto;
    margin-top:50px;
    max-width: 600px;

    .note-item{
      margin-bottom:10px;
      a{
        text-decoration: none;
        border-bottom: 1px solid $primary;
      }
    }

    p{
      font-size: .9em;
      color: $primary;
    }
  }

}

