@import "Variable";

.portal-page-content{

  max-width: 1000px;
  margin:0 auto;


  h1,h2,h3,h4,h5,h6{
    font-weight: normal;
  }

  ul{

    margin: 0 0 20px;


    li{
      list-style: none;
      position: relative;
      padding-left: 20px;
      margin-bottom: 5px;

      &:before{
        width: 6px;
        height: 6px;
        background: $primary;
        content: '';
        position: absolute;
        left: 0;
        top:9px;
        border-radius: 50%;
      }

      ul, ol{
        margin-top: 20px;
      }

      ul{

        li{
          &:before{
            background: #939393;
          }
        }
      }
    }
  }


  @media (max-width: 767px) {
    margin-left:0;
    margin-right:0;
  }

}