@import "Variable";

.page-content{

  padding: 50px;
  box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.1);//5px 5px 30px rgba(51, 51, 51, 0.19);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  position: relative;
  background: #FFF;
  border-top: 4px solid #37c3ff;
  border-bottom: 4px solid #37c3ff;

  h2{
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2em;
    //font-weight: 400;
  }



  &:before,
  &:after {
    display: none;
    content: '';
    position: absolute;
    z-index: 5;
    height: 5px;
    left: 0;
    right: 0;
    background: #6bebfe;
    background: -moz-linear-gradient(left, #6bebfe 0%, #768cff 100%);
    background: -webkit-linear-gradient(left, #6bebfe 0%, #768cff 100%);
    background: linear-gradient(to right, #6bebfe 0%, #768cff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bebfe', endColorstr='#768cff',GradientType=1 );
  }


  &:before {
    top: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:after {
    bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }


  &.no-bottom-top-color{
    &:before,
    &:after {
      display: none;
    }
  }

  &.no-radius{
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &:before,
    &:after {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

  }

  &.no-pad{
    padding: 0;
  }


  @media (max-width: 767px) {

    padding: 24px;

    h2{
      font-size: 1.5em;
    }

  }


  @media (max-width: 767px) {

    box-shadow: none;
    padding: 20px;

    &:before,
    &:after {
      display: none;
    }

    h2{
      font-size: 1.5em;
    }

  }

}


.single-request-page-wrap{
  max-width: 800px;
  margin:0 auto;
  text-align: center;

  margin-bottom:50px;
}



