html.blog-post-tracker,
body.blog-post-tracker{
    overflow: hidden!important;
}

#blog-post-tracker-main{
    height: calc(100vh - 71px)!important;
    overflow: hidden;
}
#blog-post-tracker-iframe{
    height: 100%;
    width: 100%;
    border: 0;
}