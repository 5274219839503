.wrap-404{

  text-align: center;

  h1{
    font-size: 10em;
    margin-bottom: 0;
  }

  h3{
    margin-top:0;
    color: #333;
    font-size: 2em;
    text-transform: uppercase;
  }
}