@import "Variable";

@mixin bg-gradient-in-progress {
  background: #fac166;
  background: -moz-linear-gradient(left, #fac166 0%, #ffd696 100%);
  background: -webkit-linear-gradient(left, #fac166 0%,#ffd696 100%);
  background: linear-gradient(to right, #fac166 0%,#ffd696 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fac166', endColorstr='#ffd696',GradientType=1 );
}

@mixin bg-gradient-completed {
  background: #7d94f4;
  background: -moz-linear-gradient(left, #7d94f4 0%, #4dbeff 100%);
  background: -webkit-linear-gradient(left, #7d94f4 0%,#4dbeff 100%);
  background: linear-gradient(to right, #7d94f4 0%,#4dbeff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d94f4', endColorstr='#4dbeff',GradientType=1 );
}


@mixin bg-gradient-pending {
  background: #84cb88;
  background: -moz-linear-gradient(left, #84cb88 0%, #8bda8f 100%);
  background: -webkit-linear-gradient(left, #84cb88 0%,#8bda8f 100%);
  background: linear-gradient(to right, #84cb88 0%,#8bda8f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84cb88', endColorstr='#8bda8f',GradientType=1 );
}

@mixin bg-gradient-pending-hover {
  background: #8bda8f;
  background: -moz-linear-gradient(left, #8bda8f 0%, #84cb88 100%);
  background: -webkit-linear-gradient(left, #8bda8f 0%,#84cb88 100%);
  background: linear-gradient(to right, #8bda8f 0%,#84cb88 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bda8f', endColorstr='#84cb88',GradientType=1 );
}


.project-scope-wrap{


  .form-wrap{
    margin: 0 auto;
    max-width: 500px;

    h4{
      color: #6fda85;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1rem;
      text-align: center;
    }

    h3{
      text-align: center;
      color: #333333;
      font-size: 1.75rem;
      margin-bottom:2rem;
    }

    table.project-scope-items{
      margin-bottom:1rem;
      width:100%;

      tr{
        list-style: none;
        text-align: left;

        td:first-child{
          padding-left:50px;
        }
        td:last-child{
          padding-right:50px;
        }

        td{
          background: #f9f9f9;
          padding: 10px 0;
        }

        &.item:first-child{
          td{
            padding-top: 20px;
          }
        }

        &.item:last-child{
          td{
            padding-bottom: 20px;
          }
        }



        span{
          float: right;
          font-weight: 600;
        }

        &.total{

        }
      }
    }

    .total{
      text-transform: uppercase;
      font-weight: bold;
      color: #c9c9c9;
      font-size: .9rem;
      padding-left:50px;
      padding-right:50px;
      padding-top: 10px;
      padding-bottom:1rem;
      margin-bottom:2rem;
      border-bottom: 1px solid #f9f9f9;


      &:after{
        clear: both;
        content: '';
        display: block;
      }

      span{
        color: #333;
        font-size: 1.6rem;
        float: right;
        margin-top:-10px;
      }
    }


    .item-wrap{
      margin-left:-50px;
      margin-right:-50px;
    }

    .field{
      margin-bottom:2rem;

      .form-label{
        margin-bottom:5px;
      }
    }

    .terms{
      margin-top:10px;

      .terms-link{
        color: $primary;
        cursor: pointer;
        text-decoration: none;
      }
    }



    @media (max-width: 767px) {
      h4{
        font-size: .8rem;
      }

      h3{
        font-size: 1.3rem;
      }

      .total{
        font-size: .7rem;

        span {
          font-size: 1.3rem;
        }
      }
    }

  }


  .project-scope-details-wrap{

    max-width: 1200px;
    margin:0 auto;
    padding: 10px 0 10px 0;

    h3{
      color: #333;
      font-size: 1.3em;
      margin-bottom:10px;
    }

    .project-scope-item{
      margin-bottom: 15px;
      box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
      padding: 0 40px;
      border-radius: 5px;
      font-size: .84em;
      background: #FFF;

      .psi-wrap{
        padding: 40px 0;
      }

      p{
        margin-bottom:10px;

      }

      ul{
        margin-bottom: 10px;

        li{
          position: relative;
          list-style: none;
          padding-left: 16px;
          margin-bottom: 3px;

          &:before{
            position: absolute;
            content: '';
            left: 0;
            top:8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $primary;
          }

        }

        ul{

          margin: 3px 0 ;

          li:before{
            background: #b5b5b5;
          }
        }
      }


      .psi-col-1{

        .psi-wrap{
        }
      }

      .psi-col-2{
        position: relative;


        .psi-wrap-inner{

          left: 0;
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

        }

        .psi-wrap{
          background: #faf8f5;
          position: absolute;
          top:0;
          bottom:0;
          left:15px;
          right:15px;
        }
      }

      .psi-col-3{

        .psi-wrap{
        }

      }

      @media (max-width: 991px) {

        padding: 0;

        .psi-col-1{

          .psi-wrap{
            padding: 40px 0 0 40px;
          }
        }

        .psi-col-2{

          .psi-wrap{
            padding: 0;
          }
        }

        .psi-col-3{

          .psi-wrap{
            padding: 40px;
          }
        }

      }

      @media (max-width: 767px) {
        padding: 0;

        .psi-col-1,
        .psi-col-2,
        .psi-col-3{
          .psi-wrap{
            padding: 30px;
          }
        }
        .psi-col-2{

          .status-wrap{
            margin-top: 0;
          }

          .psi-wrap,
          .psi-wrap-inner{
            transform: none;
            position: relative;
            top:auto;
            left:auto;
            right:auto;
            bottom: auto;
            padding: 0;
          }
        }

      }

    }

    .approved-by{
      color: #b2b2b2;
      font-weight: 500;
      font-size: .9em;
    }



    .status-wrap{
      max-width: 300px;
      margin:0 auto;
      background: #faf8f5;
      padding:  20px 10px;
      border-radius: 5px;


      .date{
        font-weight: bold;
        margin-top:10px;
        color: #999999;
      }

      .completion-txt{
        text-transform: uppercase;
        font-size: .7em;
        margin-top:10px;
        font-weight: bold;
        color: #c1c1bf;
      }

      .status{
        font-weight: bold;
        margin-bottom:10px;
      }

      .project-scope-status-label{

        span{
          display: inline-block;
          border-radius: 40px;
          padding: 5px 20px;
          color: #FFF;
          font-weight: 700;
          text-transform: uppercase;
          font-size: .8rem;


          &.pending{
            @include bg-gradient-pending;
            cursor: pointer;

            &:hover{
              @include bg-gradient-pending-hover;
            }
          }

          &.in-progress{
            @include bg-gradient-in-progress;
          }

          &.completed{
            @include bg-gradient-completed;
          }


        }

      }

    }

    .item-total{
      text-align: right;

      font-weight: 500;

      span{
        font-weight: normal;
        color: #84cb88;
        display: inline-block;
        padding-left:5px;
      }

      .in-progress{
        span{
          color: #fac166;
        }
      }

      .completed{
        span{
          color: #7d94f4;
        }
      }

      .pending{
        span{
          color: #84cb88;
        }
      }
    }

    .items{
      width:100%;

      .item{

        &:after{
          display: block;
          clear: both;
          content: '';
        }

        .title{
          float: left;
          margin-bottom:5px;
          font-weight: 500;
          color: #333333;
        }

        .hours{
          text-align: right;
          font-weight: bold;
          color: #333;
        }

        .item-progress{
          height: 6px;
          border-radius: 10px;
          background: #efefef;
          clear: both;
          margin-bottom:10px;
          overflow: hidden;
          position: relative;

          span{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            display: block;
            transition: all .4s ease;
          }

          &.pending{

            span{
              @include bg-gradient-pending;
            }
          }

          &.in-progress{

            span{
              @include bg-gradient-in-progress;
            }
          }

          &.completed{

            span{
              @include bg-gradient-completed;
            }
          }

        }
      }

    }


    button.edit{
      color: red;
      text-transform: uppercase;
      font-size: .8rem;
      font-weight: bold;
      background: none;
      padding:0;
      border: 0;
      outline: none;
    }

    @media (max-width: 991px) {

      .items{
        margin-top: 30px;

      }
    }

    @media (max-width: 767px) {

      h3{
        font-size: 1.3rem;
      }
      .status-wrap{
        margin-top: 30px;
        max-width: 100%;
      }
    }


  }




}


#edit-project-scope-modal{


  .range-slider{
    width:100%;
  }

  .project-scope-list-items{

    .item{
      padding: 15px;
      border: 1px solid #ededed;
      margin-bottom: 10px;
      background: #fafafa;
      border-radius: 3px;
      position: relative;

      .remove-item{
        border: 1px solid #ededed;
        background: #FFF;
        position: absolute;
        height: 30px;
        width:30px;
        text-align: center;
        line-height: 26px;
        top: -10px;
        right:-10px;
        content: '';
        font-size: 13px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .4s ease;

        &:hover{
          background: red;
          color: #FFF;
        }
      }
    }
  }


  .add-project-scope-item{
    padding: 0 0 20px;
    text-align: right;

    span{
      color: $primary;
      cursor: pointer;
    }
  }
}

.project-scope-top-buttons{
  position: relative;
  max-width: 1200px;
  margin:0 auto;

  .ps-button{

    position: relative;
    display: inline-block;
    z-index: 10;
    height: 35px;
    font-size: 13px;
    color: #666;

    .fa-filter{
      color: #666;
      font-size: 11px;
    }

    a{
      color: #333;
    }

    h4{
      font-size: 14px;
    }

  }

  ul{

    display: inline-block;

    li{
      list-style: none;
      font-size: 14px;

    }

    &.ps-filter{

      margin-left:20px;

      li{

        display: inline-block;
        margin-right: 5px;

        a{
          display: inline-block;
          padding: 3px 15px 5px;
          position: relative;
          text-decoration: none;
          font-size: 13px;
          border-radius: 30px;
          font-weight: 600;
          color: #FFF;
          transition: all .4s ease;
          border: 1px solid rgba(255,255,255,0);


          &:hover{
            box-shadow: 0 0 5px 0 rgba(0,0,0,.4);
            border: 1px solid rgba(255,255,255,.6);
          }

          &:after{
            bottom:2px;
            left:15px;
            right: 15px;
            content: '';
            position: absolute;
            opacity: 1;
            background: #FFF;
            height: 1px;
            transform: scale(0);
            transition: all .4s ease;
          }

          &.active{
            box-shadow: 0 0 5px 0 rgba(0,0,0,.4);
            border: 1px solid rgba(255,255,255,.6);

            &:after{
              //transform: scale(1);
              display: block;
            }
          }
        }

        &.all{
          a{
            background: #b2b2b2;
          }
        }
        &.pending{
          a{
            background: #84cb88;
          }
        }

        &.in-progress{
          a{
            background: #fac166;
          }
        }

        &.completed{
          a{
            background: #7d94f4;
          }
        }

      }
    }
  }

  @media (max-width: 600px) {
    ul.ps-filter{

      margin-left:10px;

      li{
        margin-right: 3px;
        a{
          padding: 2px 8px 3px;
          font-size: 12px;
          &:after{
            right:8px;
            left:8px;
          }
        }

      }
    }
  }
}

.project-scope-no-results{
  padding: 50px 0;
  color: #666;
  text-align: center;
  font-size: 14px;
}


.checkbox-ui{

  display: inline-block;
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;

  span.checkbox{
    left: 0;
    top: 3px;
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px);
    transition: all .3s ease;


    &.active{
      &:after{
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 18px;
        font-size: 10px;
        content: '\f00c';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $primary;
      }

    }

    &:hover{
      border-color: $primary;
    }

    input{
      position: relative;
      z-index: 10;
      opacity: 0;
      //visibility: hidden;
    }
  }
}