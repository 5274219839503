@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-extrabold-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-extrabold-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-extrabold-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
  font-weight: 900;
  font-style: normal;

}


@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-bold-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-bold-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-bold-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-bold-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: 700;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-boldit-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-boldit-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-boldit-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-boldit-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-boldit-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-boldit-webfont.svg#proxima_nova_rgbold_italic') format('svg');
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-semibold-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-semibold-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-semibold-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-semibold-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-semibold-webfont.svg#proxima_novasemibold') format('svg');
  font-weight: 500;
  font-style: normal;

}


@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-semibolditalic-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-semibolditalic-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-semibolditalic-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-semibolditalic-webfont.svg#proxima_novasemibold_italic') format('svg');
  font-weight: 500;
  font-style: italic;

}


@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-regular-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-regular-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-regular-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-regular-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-regitalic-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-regitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-regitalic-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-regitalic-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-regitalic-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-regitalic-webfont.svg#proxima_novaregular_italic') format('svg');
  font-weight: 400;
  font-style: italic;

}

@font-face {
  font-family: 'proxima_nova';

  src: url('../fonts/proxima/proximanova-light-webfont.eot');
  src: url('../fonts/proxima/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proximanova-light-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proximanova-light-webfont.woff') format('woff'),
  url('../fonts/proxima/proximanova-light-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proximanova-light-webfont.svg#proxima_novalight') format('svg');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova-lightitalic-webfont.eot');
  src: url('../fonts/proxima/proxima_nova-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova-lightitalic-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova-lightitalic-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova-lightitalic-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova-lightitalic-webfont.svg#proxima_novalight_italic') format('svg');
  font-weight: 300;
  font-style: italic;

}


@font-face {
  font-family: 'proxima_nova';
  src: url('../fonts/proxima/proxima_nova_thin-webfont.eot');
  src: url('../fonts/proxima/proxima_nova_thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima/proxima_nova_thin-webfont.woff2') format('woff2'),
  url('../fonts/proxima/proxima_nova_thin-webfont.woff') format('woff'),
  url('../fonts/proxima/proxima_nova_thin-webfont.ttf') format('truetype'),
  url('../fonts/proxima/proxima_nova_thin-webfont.svg#proxima_novathin') format('svg');
  font-weight: 200;
  font-style: normal;

}





html,body{
  padding: 0;
  margin: 0;
  overflow: hidden;
}

body{
  //font-family: proxima_nova, Helvetica, Arial, sans-serif;
  font-family: acumin-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  font-size: 16px;
  //background: #FFF;
  font-weight: 400;

  background: #f4f6fc;//f4f6fc
}

.page-wrap{
  overflow: hidden;
  padding-bottom: 50px;

}

.wrap{
  padding: 50px 0;

  @media (max-width: 600px) {
      padding: 20px 0;
  }

}


.portal-page-content,
.project-scope-details-wrap,
.make-request-form-wrap,
.service-plan-content-text,
.service-item{

  //font-size: 15px;
  //color: #000;
  //font-weight: 300;

  strong{
    //font-size: 16px;
    //font-weight: 400;
    //letter-spacing: -.25px;
  }
}


main{
  overflow: auto;
}

ul{
  margin: 0;
  padding: 0;
}

img{
  max-width: 100%;
  height: auto;
}

h1,h2,h3,h4,h5,h6{
  color: $primary;
}

.text-primary, a{
  color: $primary;
}

a:hover{
  color: #0092D6;
}

.text-primary{
  color: $primary!important;
}

.container-fluid{
  max-width: 1300px;
}


body .sweet-alert{

  padding: 30px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.4);

  h2{
    color: #333!important;
    font-size: 1.2em!important;
    margin-top: 0!important;
    margin-bottom:20px;
  }

  p{
    color: #333!important;
    font-size: 14px;
  }

  button {
    font-size: 14px;
    border-radius: 3px;
    font-weight: 600;
    padding: 6px 20px;
  }

  button.cancel{

    background-color: #6b6b6b;
    transition: all .4s ease;

    &:hover{
      background-color: #545454;
    }

  }
  button.confirm{
    background-color: $primary!important;
    box-shadow: none!important;
    transition: all .4s ease;

    &:hover{
      background-color: #0089C9!important;
    }
  }

  strong{
    font-weight: bold;
  }

  &.container-delete-alert{


    h2 {
      color: #da493f !important;
    }
    button.confirm{
      background-color: #da493f!important;

      &:hover{
        background-color: #c24138!important;
      }
    }

  }
}



.modal{

  .modal-header{
    padding: 0;
    position: relative;
    border:0;

    h5 {
      background: #6bebfe;
      background: -moz-linear-gradient(left, #6bebfe 0%, #768cff 100%);
      background: -webkit-linear-gradient(left, #6bebfe 0%, #768cff 100%);
      background: linear-gradient(to right, #6bebfe 0%, #768cff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bebfe', endColorstr='#768cff',GradientType=1 );
      padding: 20px 30px !important;
      width:100%;
      color: #FFF !important;
      text-transform: uppercase;
      font-size: 20px !important;
    }



  }

  .close{
    position: absolute;
    width: 30px;
    height: 30px;
    background: #718eff;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    right: 6px;
    top: -18px;
    color: #FFF;
    text-shadow: none;
    transition: all .4s ease;
    font-family: Times;
    font-weight: normal;

    &:hover{
      opacity: 1!important;
      box-shadow: 0 0 0 3px #FFF;
    }

    span{
      display: inline-block;
    }
  }

  &.no-header{
    .close{
      top:-15px;
      right:-10px;
      box-shadow: 0 0 0 3px #FFF;
      z-index: 10;

      &:hover{
        background: #6bebfe;
      }
    }
  }

  .modal-content{
    border: 0;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .modal-body{
    padding: 35px 30px !important;

    .alert{
      margin-top: 10px;
    }

    textarea{
      resize: none;
    }

    .form-label{
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom:5px;
      font-size: 13px;
    }

    button{
      border: 0!important;
    }
  }

  .modal-footer{
    padding: 15px 30px;
  }

  button{
    margin-top:0!important;
  }
}



.fa-spinner.fa-spin{
  opacity: .3;
}

@-webkit-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}
@-moz-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}
@-o-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}

.loading-item{

  -webkit-animation: fadeInFadeOut 1s infinite ease;
  -moz-animation: fadeInFadeOut 1s infinite ease;
  -o-animation: fadeInFadeOut 1s infinite ease;
}




.load-bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #FFF;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: #46d4d7;
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: $primary;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: #FFF;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {left: 50%; width: 0;z-index:100;}
  33.3333% {left: 0; width: 100%;z-index: 10;}
  to {left: 0; width: 100%;}
}