@import "Variable";

.service-item{

  padding: 30px;
  border: 1px solid #e1edf5;
  border-radius: 3px;

  .billing-date{
    color: $primary;
  }

  .last{
    text-align: right;
  }

  .cancel-link{
    font-size:13px;
    color: #a2a2a2;
    margin-top:12px;
    display: inline-block;
    cursor: pointer;

    &:hover{
      color: $primary;
    }
  }

  @media (max-width: 767px) {

    text-align: center;

    .last{
      margin-top:20px;
    }
  }

}

.account-page-wrapper{
  position: relative;

  h2{
    font-size: 1.3em;
  }

}

.payment-policies-wrap{

  margin-top: 70px;



  .card{

    border: 1px solid #e1edf5;

    a{
      color: $primary;
    }

    .card-header {
      padding: 0;
      background-color: #F4F9FC;
      border-color: #e1edf5;
    }

    .card-body{
      padding: 15px 30px;
    }

    button{
      padding: 15px 30px;
      width:100%;
      text-align: left;
      display: block;
      text-decoration: none;
      color: #333;
      transition: all .3s ease;
      background-color: #FFF;

      &:hover{
        background-color: #F4F9FC;
        color: $primary;
      }
    }

  }
}


.card-billing-service-plan{

  padding: 20px;

  span.amount{
    font-weight: bold;
    display: block;
  }
}


#cancelSubscriptionModal{

  input{
    text-transform: uppercase;
  }
}

.billing-text-section{
  margin-top: 30px;

  padding: 0!important;
  background: none!important;
  box-shadow: none!important;
}