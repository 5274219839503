@import "Variable";



.report-tabs-wrap{

  background: none;
  transition: background .4s ease;

  &:after{
    content: '';
    display: block;
    position: relative;
    height: 1px;
  }

  @media (min-width: 992px) {

    &.fixed{
      position: fixed;
      top: 111px;
      background: #FFF;
      left: 0;
      right: 0;
      z-index: 122;
      border-bottom: 1px solid red;
      border-bottom: 1px solid rgba(72, 94, 144, 0.16);
      box-shadow: 0 0 10px rgba(28, 39, 60, .05);


      .report-tabs {
        margin-bottom: 10px;
        padding-top: 10px;
        z-index: 25;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

    }

  }

}

.report-tabs-wrap-height{
  height: 0;
}

.report-tabs{
  margin-bottom: 20px;
  padding-top:10px;
  z-index: 25;

  #report-tabs-current-page{
    display: none;
  }

  ul{
    list-style: none;

    li{
      display: inline-block;
      font-weight: 400;
      margin-left: 0;
      font-size: 14px;
      margin-right: 7px;
      margin-bottom:5px;

      a{
        color: #73777a;
        text-decoration: none;
        transition: background, color .4s ease;
        padding:7px 15px 7px 35px;
        display: block;
        position: relative;
        border: 1px solid rgba(72, 94, 144, 0.16);
        box-shadow: 0 0 1px rgba(28, 39, 60, .05);
        background: #FFF;
        border-radius: 4px;

        svg{

          width: 16px;
          height: auto;
          display: block;
          margin-left:auto;
          margin-right: auto;
          //filter: grayscale(.5);
          margin-bottom: 10px;
          top:50%;
          transform: translateY(-50%);
          position: absolute;
          left: 10px;
          opacity: .5;
          filter: grayscale(1);


        }


        &:hover{
          color: $primary;

        }

        &.current-page{
          background: $primary;
          color: #FFF;
          font-weight: 600;

          svg{
            opacity: 1;
            filter: grayscale(0);
          }
        }
      }
    }
  }



  @media (max-width: 991px) {

    ul{
      li {
        font-size: 12px;

        a{
          padding: 7px 10px 7px 30px;
        }
      }
    }
  }

  @media (max-width: 767px) {

    text-align: center;

    position: fixed;
    top: 80px;
    right: 20px;
    padding: 0;
    background: none;
    border: 0;
    width:170px;


    #report-tabs-current-page{
      display: block;
      text-align: left;
      background: #FFF;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 4px;
      border-radius: 4px;
      border: 1px solid #ededed;
      color: $primary;

      span{
        display: inline-block;
        position: relative;
        padding-right: 20px;
        padding-left: 5px;
        white-space: nowrap;
        width:100%;

        &:after{
          content: "\f078";
          color: #dedede;
          font-weight: bold;
          position: absolute;
          right: 4px;
          top:3px;
          font-size: 11px;
          font-family: "Font Awesome 5 Free";
        }
      }
    }

    ul{

      display: none;
      position: absolute;
      background: #FFF;
      z-index: 10;
      left: auto;
      width: 220px;
      right: 0;
      top:100%;
      margin-top: 2px;
      border-radius: 4px;

      border: 1px solid rgba(72, 94, 144, 0.16);
      box-shadow: 0 0 10px rgba(28, 39, 60, .05);

      &.show{
        display: block;
      }


      li{
        display: block;
        width: 100%;
        margin-top:0;
        margin-bottom:0;
        text-align: left;
        padding: 5px 0;

        a{
          padding:7px 15px 7px 40px;
          border: 0;

          svg{
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 0;
          }
        }

        a.current-page {
          color: #37c3ff;
          background: none;

        }

        + li{
          border-top: 1px solid #ededed;
        }
      }
    }
  }

  @media (max-width: 375px) {
    width:150px;
  }
}


.report-breadcrumbs{

  position: relative;
  z-index: 23;
  padding: 0 0 10px ;

  font-weight: 400;
  font-size: 14px;
  color: #73777a;


  a{
    color: #000;
    text-decoration: none;
    transition: all .4s ease;

    &:hover{
      color: #37c3ff;


    }
  }

  i.fa{
    margin: 0 15px;
    font-size: 8px;
    transform: translateY(-2px);

    @media (max-width: 413px) {
      margin:0 8px;
    }
  }

  .slash{
    display: inline-block;
    color: #c3c3c3;
    margin: 0 15px;
  }


  i.fa-chevron-left{
    margin: 0 5px 0 0;
    font-size: 10px!important;
    transform: translateY(-3px);

    @media (max-width: 767px) {
      transform: translateY(0);
    }
  }

  .report-dates{
    display: inline-block;
    position: relative;
    background: $primary;
    padding: 4px 10px;
    border-radius: 3px;
    color: #FFF;
    cursor: pointer;
    transition: all .4s ease;
    font-size: 14px;



    &:hover{
      background: #28b6f3;
    }




    .fa-chevron-down{
      font-size: 11px;
      margin-left: 5px;

      @media (max-width: 767px) {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }

    ul{
      position: absolute;
      background: $primary;
      border: 1px solid #32B1E8;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,.1);
      border-radius: 3px;
      width: 200px;
      display: none;
      left: 0;
      top:100%;
      max-height: 300px;
      overflow: auto;
      margin-top:2px;

      &.show{
        display: block;
      }

      li{
        list-style: none;


        + li{
          border-top: 1px solid #32B1E8;
        }

        a{
          color: #FFF ;
          padding: 5px 20px;
          display: block;
          transition: all .4s ease;
          text-transform: none;
          font-weight: normal;
          font-size: 16px;

          &:hover{
            background: #32B1E8;
            text-decoration: none;
          }
        }

        &.active{
          background: #32B1E8;
          a{
            color: #FFF;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    top: 130px;
    background: #FFF;
    left: 0;
    right: 0;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    box-shadow: 0 0 1px rgba(28, 39, 60, .05);
    font-size: 12px;


    .report-dates{
      font-size: 12px;
      width:120px;
    }
  }

  @media (min-width: 768px) {

    font-size: 20px;
    font-weight: 500;

    .report-dates{

      background: none;
      padding: 4px 10px;
      color: #000;
      font-size: 20px;
      position: relative;
      margin-left:-10px;

      .fa-chevron-down{
        font-size: 9px;
        margin-left:10px;
        transform: translateY(-4px);
      }


      &:hover{
        color: $primary;
        background: none;
      }

    }

  }

}

.report-admin-bar{
  text-align: right;
  margin-bottom:20px;

  .btn{
    font-size: 14px;

    i{
      font-size: 12px;
      margin-right:4px;
    }
  }
}

.report-blocks {

  margin-top: 20px;

  @media (max-width: 767px) {
    //margin-top: 60px;
  }

  .wrap{
    padding-top: 5px;

    @media (max-width: 767px) {
      padding-top: 45px;
    }
  }

  .report-block {
    margin-bottom: 15px;
    //box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    border: 1px solid rgba(72, 94, 144, 0.16);
    box-shadow: 0 0 10px rgba(28, 39, 60, .05);
    padding: 40px;
    border-radius: 5px;
    font-size: .84em;
    background: #FFF;
    position: relative;

    .report-block-content{
      position: relative;
    }

    .report-box-header{
      display: none;
    }


    .report-block-options{
      position: absolute;
      right:20px;
      top:15px;
      z-index: 22;

      .fa{
        cursor: pointer;
      }

      ul{
        position: absolute;
        width: 220px;
        right: 0;
        background: #FFF;
        border: 1px solid rgba(72, 94, 144, 0.16);
        box-shadow: 0 0 10px rgba(28, 39, 60, .05);
        border-radius: 5px;
        list-style: none;

        li{
          padding:5px 10px;
          cursor: pointer;

          + li{
            border-top: 1px solid rgba(72, 94, 144, 0.16);
          }
        }
      }
    }


    &.hidden-block{
      .report-block-content{
        opacity: .5;
      }
    }


    .keyword-rankings-filter{
      position: absolute;
      //top: 127px;
      //left: 60px;
      z-index: 22;

      bottom: -48px;
      left: 17px;

      @media (max-width: 767px) {
        //top: 103px;
        //left: 31px;
      }
    }


    .portage-color {
      background: #888cef;
      background: linear-gradient(90deg ,#6f3ae2 0,#3e58e0);
    }

    .pink-color {
      background: #fb6fac;
      background: linear-gradient(90deg,#d4165e 0,#f4586d);
    }
    .green-color {
      background: #5bd2b6;
      background: linear-gradient(90deg,#1bcff2 0,#22e8d9);
    }

    .blue-color {
      background: #00bcff;
      background: linear-gradient(90deg,#00bcff 0,#007dff);
    }

    .session-boxes{
      padding-bottom: 30px;

      &:after{
        display: block;
        content: '';
        position: relative;
        clear: both;
      }
      .session-box {
        box-sizing: border-box;
        float: left;
        width: 33.33%;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
      }
      .session-box-content {
        padding: 20px;
        color: #fff;
        height: 120px;
        box-sizing: border-box;
        text-align: left;
        border-radius: 5px;
        position: relative;
        padding-left: 80px;
      }

      .session-box-icon {

        position: absolute;
        left: 20px;
        top: 25px;

        span {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 2px solid #fff;
          text-align: center;
          line-height: 32px;
          box-sizing: border-box;
          margin-bottom: 10px;
          font-size: 13px;

          &:before{
            margin-left:2px;
          }
        }

      }

      .session-box-sessions {
        font-weight: 700;
        font-size: 22px;
      }

      p {
        font-size: 11px;
      }

      @media (max-width: 991px){



        .session-box-icon {
          left: 10px;
        }

        .session-box-content{
          padding: 10px 10px 10px 60px;
        }

        .session-box-text{
          line-height: 1.1;
        }

        p{
          line-height: 1.2;
          margin-top: 5px;
          font-size: 10px;
        }

      }


      @media (max-width: 767px){

        .session-box-content{
          padding: 10px;
          height: 160px;
          text-align: center;
        }

        .session-box-icon {
          left: 0;
          top:0;
          position: relative;
        }

        .session-box-sessions{
          font-size: 16px;
          padding-bottom:5px;
        }

        .session-box-text{
          font-size: 12px;
          line-height: 1.1;
        }

        p{
          line-height: 1.2;
          margin-top: 5px;
          font-size: 9px;
        }
      }
    }

    @media (max-width: 767px){
      .business-search-vs-maps-chart-info{
        .session-boxes{
          .session-box{
            width: 50%;
          }

          .session-box-content{
            height:  135px;
          }
        }
      }
    }





    .report-box{

      .table-wrap{

        margin-left: -40px;
        margin-right: -40px;
        margin-top: 10px;
        margin-bottom: -40px;

        table{
          width: 100% !important;
          //border: 1px solid #ededed !important;
          background: #5c64f7 !important;
          background: linear-gradient(90deg, #5c64f7 0, #30a0f6) !important;

          tbody {
            border: 0!important;
          }

          tr:first-child, table{
            background: 0 0!important;
          }

          tr:first-child td,
          tr:first-child th {
            color: #FFF;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 10px;
            font-weight: 500;
            border: 0 !important;
          }

          tr{
            background: #fff;
          }

          tr:nth-child(even){
            background: #fdfdfd;
          }

          tr td, tr th{
            border-top: 1px solid #ededed!important;
            border-bottom: 1px solid #ededed!important;
            //border-left: 1px solid #ededed!important;
            //border-right: 1px solid #ededed!important;
            padding: 15px 15px;

            &:first-child{
              padding-left:40px;
            }
          }
        }

      }

      @media (max-width: 767px) {

        .table-wrap{
          margin-left: -20px;
          margin-right: -20px;
          margin-top: 0;
          margin-bottom: -20px;
          //width:100%;
          overflow-x: scroll;
        }

      }
    }







    .btn-danger,
    .btn-primary,
    .btn-secondary{
      border: 0;
      border-radius: 3px;
      text-transform: uppercase;
      font-size: 12px!important;

      .fa{
        color: rgba(255,255,255,.5);
        position: relative;
        padding-right:15px;
        margin-right: 10px;

        &:after{
          position: absolute;
          right: 0;
          top: -13px;
          bottom: -13px;
          content: '';
          width:1px;
          background: rgba(255,255,255,.2);
        }
      }
    }

    iframe{
      width:100%;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05);
    }

    h2{
      font-size: 22px;
      //color: #001737;

    }

    .report-block-header{
      display: flex;
      justify-content: space-between;
      padding-bottom:20px;

      .description{
        color: #999;
        max-width: 600px;
      }


      .chart-tab{
        border-radius: 3px;


        span{
          display: inline-block;
          padding: 5px 0;

          width: 95px;
          text-align: center;
          cursor: pointer;

          border: 1px solid #c0ccda;
          color: rgba(27, 46, 75, 0.7);
          border-right-color: #c0ccda;

          &.active{
            background-color: #f5f6fa;
          }

          &:last-child{
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
          }

          &:first-child{
            border-right: 0;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
          }
        }
      }

      @media (max-width: 767px) {
        display: block;
      }
    }

    .report-block-footer{
      margin-top: 20px;

      .footer-item{
        display: inline-block;
      }
    }

    .btn{
      margin-left:2px;
      margin-right:2px;
      outline: none!important;
      box-shadow: none!important;
    }

    .btn-filter{
      background: #FFF;
      color: #333;
      border: 1px solid rgba(72, 94, 144, 0.16);
      font-size: 14px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      i{
        margin-right:5px;
        font-size: 12px;
        color: $primary;
      }

      span{
        color: $primary;
      }

      &:hover{
        border-color: $primary;
        color: #1b2e4b;
        //box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
      }

      @media (max-width: 991px) {
        max-width: 450px;
      }

      @media (max-width: 767px) {
        max-width: 255px;
      }


    }


    .reports-keyword-ranking-pages-wrap{
      margin-top: 20px;
    }

    .reports-keyword-ranking-pages{

      position: relative;
      padding-bottom:10px;
      cursor: pointer;
      display: inline-block;
      margin-left:7px;

      ul{
        position: absolute;
        list-style: none;
        color: inherit;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid rgba(72, 94, 144, 0.16);
        width: 250px;
        max-width: none;
        z-index: 3001;
        font-size: 15px;
        line-height: 1em;
        top:100%;
        left:0;
        display: none;
        box-shadow: 0 0 10px rgba(28, 39, 60, .05);

        &.show{
          display: block;
        }

        &:after, &:before {
          bottom: 100%;
          left: 15%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #FFF;
          border-width: 5px;
          margin-left: -5px;
        }
        &:before {
          border-color: rgba(222, 222, 222, 0);
          border-bottom-color: rgba(72, 94, 144, 0.16);
          border-width: 6px;
          margin-left: -6px;
        }



        li{
          padding: 10px;
          cursor: pointer;
          font-size: 14px;
          position: relative;

          .sort-handle{
            display: none;
            margin-right:10px;
            position: absolute;
            left:10px;
            top: 10px;
          }

          + li{
            border-top: 1px solid #ddd;
          }

          &.active{
            color: $primary;
          }

          &:hover{
            background: #fafafa;
          }
        }


        li.action{
          display: flex;

          background: #333;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          text-align: center;
          border-top:0;
          padding: 0;
          text-transform: uppercase;
          font-size: 12px;

          span{
            color: #FFF;
            display: block;
            width:50%;
            height: 100%;
            padding: 10px;

            &:nth-child(1){

              border-right: 1px solid #000000;
            }
          }
        }

      }


      &.sort-page{
        ul{
          li{

            padding-left:30px;

            .sort-handle{
              display: inline-block;
            }
          }
        }
      }

      @media (max-width: 767px) {
       margin-left: 0;
      }

    }

    @media (max-width: 767px) {
      padding:20px;

      h2{
        font-size: 18px;
      }
    }

  }

  .chart-legend-text{

    margin-bottom: 40px;

    ul{
      margin: 0;
      padding: 0;

      &:after{
        clear: both;
        content: '';
        display: block;
      }

      li{
        list-style: none;
        text-align: left;
        width: 33.33%;
        float: left;
        padding: 0 5px;

        .info{
          color: #FFF;
          border-radius: 6px;
          padding: 20px;
          position: relative;
          height:  130px;
          padding-left: 80px;

        }


        .fa {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 2px solid #fff;
          text-align: center;
          line-height: 32px;
          box-sizing: border-box;
          margin-bottom: 10px;
          font-size: 13px;
          left: 20px;
          top: 25px;
          position: absolute;

          &:before{
            margin-left:2px;
          }
        }


        .score {
          font-weight: 700;
          font-size: 22px;
        }

        p{
          font-size: 11px;
          margin:0 auto;
        }

        h4{
          font-size: 13.44px;
          color: #FFF;
          font-weight: bolder;
          text-transform: uppercase;
        }
      }
    }

    @media (max-width: 991px){


      ul{
        li{

          .fa {
            left: 10px;
          }

          .info{
            padding: 10px 10px 10px 60px;

          }

          p{
            line-height: 1.2;
            margin-top: 5px;
            font-size: 10px;
          }
        }
      }

    }

    @media (max-width: 767px){

      ul{

        li{

          .fa {
            position: relative;
            top: 0;
            left: 0;
          }

          .info{
            padding: 10px;
            height: 160px;
            text-align: center;
          }

          .score{
            font-size: 16px;
            padding-bottom:5px;
          }

          h4{
            font-size: 12px;
            line-height: 1.1;
          }

          p{
            line-height: 1.2;
            margin-top: 5px;
            font-size: 9px;
          }

        }
      }





    }


  }

}

.new-organic-keywords-page-form{

  max-width: 500px;
  border-radius: 4px;
  padding:40px;
  margin:0 auto;
  border: 1px solid #ededed;

  .btn{

    font-size: 14px;

    i{
      font-size: 12px;
      margin-right:4px;
    }

  }
}

body .daterangepickerxx{

  background: $primary;
  border-color:#36c3ff;
  font-family: acumin-pro, sans-serif;
  color: #FFF;
  font-size: 13px;
  font-weight: 700;

  &.show-ranges.ltr .drp-calendar.left,
  .drp-buttons{
    border-color: #30ace1;
  }

  .ranges li.active{
    background-color: $primary!important;
  }

  .calendar-table{
    background: none;
    border: none;
  }

  td.in-range {
    background-color: #2492df;
    color: #FFF!important;
  }

  td.active,
  td.active:hover{
    background-color: #237cc1 !important;
  }

  td.off, td.off.in-range,
  td.off.start-date, td.off.end-date{
    background: none!important;
    color: rgba(255,255,255,.5);
  }

  td.available:hover,
  th.available:hover {
    background-color: #249eee;
    border-color: transparent;
    color: inherit;
  }

  .calendar-table th,
  .calendar-table td{
    font-weight: bold;
    font-size: 12px;
  }

  .btn.cancelBtn{
    background: none;
    color: #FFF!important;
    border: 0!important;
  }

  .btn-primary{
    background: #FFF;
    color: $primary!important;

    &:after{
      display: none;
    }
  }
}



body .daterangepicker{

  font-family: acumin-pro, sans-serif;
  font-size: 13px;
  font-weight: 700;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,.2);
  border-color: rgba(72, 94, 144, 0.16);


  &.show-ranges.ltr .drp-calendar.left,
  .drp-buttons{
    border-color: rgba(72, 94, 144, 0.16);
  }

  .ranges li.active{
    background-color: $primary!important;
  }

  .calendar-table{
    background: none;
    border: none;
  }

  td.in-range {
    background: #D7F1FF;
  }

  td.active,
  td.active:hover{
    background-color: $primary !important;
  }

  td.off, td.off.in-range,
  td.off.start-date, td.off.end-date{
    background: none!important;
    //color: rgba(255,255,255,.5);
  }

  td.available:hover,
  th.available:hover {
    background-color: $primary;
    color: inherit;
  }

  .calendar-table th,
  .calendar-table td{
    font-weight: bold;
    font-size: 12px;
  }

  .btn.cancelBtn{
    background: none;
    color: #333!important;
    border: 0!important;
  }

  .btn-primary{
    background: $primary;
    color: #FFF!important;

    &:after{
      display: none;
    }
  }
}

.highcharts-xaxis-labels {
  text{
    transform: none!important;
  }
}

.report-highlights-wrap {

  #report-highlights {

    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ededed;
    border-radius: 4px;



    &.user-readonly{

      padding: 0;
      margin-bottom: 0;
      border: 0;
      border-radius: 0;
      margin-left:-20px;
      margin-right:-20px;

      .tox-editor-header{
        display: none;
      }
    }

    .tox-statusbar {
      display: none !important;
    }

    .tox-toolbar__primary{
      background: none!important;
    }

    .tox-tinymce{
      border-color: #FFF;
    }

    .tox-editor-header{
      margin-bottom: 20px;
      border: 1px solid #ededed;
      border-radius: 4px;
      overflow: hidden;
    }

  }
}




.new-reports-portal-link{
  background: #FFF;
  z-index: 101;
  text-align: center;
  padding: 7px 20px;
  border-bottom: 1px solid #ededed;
  position: absolute;
  right: 0;
  left: 0;

  a{
    font-size: 14px;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    transition: all .4s ease;

    &:hover{
      color: $primary;
    }

    span{
      color: $primary;
      text-transform: uppercase;
      font-size: 9px;
      transform: translateY(-5px);
      display: inline-block;
    }
  }


  @media (max-width: 767px) {
    position: relative;
    z-index: 2;
    margin-top:2px;
  }
}



.project-book-content-wrap.beta-portal-link-admin{

  padding-top:50px;

  .new-reports-portal-link{
    top:0;
  }

  .tiny-mce-editor .tox .tox-toolbar-wrap.fixed{
    top:120px;
  }

  @media (max-width: 767px) {
    padding-top: 0;
  }
}


.page-description{

  margin-bottom: 20px;

  .page-description-field{
    display: inline-block;
    border-bottom: 1px dashed #FFF;
    min-width: 50px;

    &:before{
      content: "\f304";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      left: -15px;
      font-size: 10px;
      top:3px;
      color: #c3c3c3;
      display: none;
    }

    &:focus{
      outline: none;
      border-bottom: 1px dashed #969696;

      &:before{
        display: block;
      }
    }


    &.editable{
      position: relative;



      &:hover{

        &:before{
          display: block;
        }
      }
    }
  }

  &.keyword-rankings-desc{
    margin-bottom:0;
    margin-top:20px;
  }

  *{
    font-size: .84rem!important;
  }

  @media (min-width: 1024px) {
    max-width: 50%;

    .description &{
      max-width: 100%;
    }
  }
}

.report-blocks .report-block.report-dashboard-block{

  padding-top: 20px;
  padding-bottom: 20px;

  .report-block-header{
    margin: 0;
    padding: 0;
  }

  h2{
    font-size: 35px;
    margin-top:30px;
    font-weight: 400;

    svg{
      width:50px;
      height:  auto;
      margin-right: 20px;
      opacity: .5;

    }
  }
}


.report-highlights-container{

  float: right;
  margin-top:-70px;
  margin-bottom:20px;

  &.hide-metrics-percent{
    .percent-increase,
    .vs-months{
      display: none;
    }
  }


  @media (max-width: 991px) {
    float: none;
    margin-bottom:30px;
    margin-top:30px;
  }

  @media (max-width: 767px) {
    margin-top:10px;
  }



}

.report-highlights{
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-items: center!important;
  width:100%;
  min-width: 600px;


  .report-highlight-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    text-align: center;
    position: relative;
    padding-left:10px;
    padding-right:10px;

    .report-highlight-item-content{
      border-radius: 5px;
    }



    &.report-highlight-users{
      h4{
        color: rgb(208, 13, 92);;
      }
      .report-highlight-item-content{
      }
    }

    &.report-highlight-pageviews{
      h4{
        color: rgb(112, 59, 227);
      }
      .report-highlight-item-content{
      }
    }
    &.report-highlight-sessions{
      h4{
        color: rgb(28, 212, 240);
      }
      .report-highlight-item-content{
      }
    }


  }

  .percent-increase{
    color: #4caf50;
    font-size: 16px;
    font-weight: 700;

    .fa{
      font-size: 10px;
      transform: translateY(-2px);
    }

    &.arrow-down{
      color: red;
    }
  }

  h5{
    font-size: 12px;
    color: #333;
    text-transform: uppercase;
    margin: 0;
  }

  h4{
    font-size: 26px;
    font-weight: 700;
    margin: 5px 0;
  }

  .vs-months{
    color: #666;
  }

  @media (max-width: 767px) {
    min-width: 100%;

    h4 {
      font-size: 22px;
    }

    h5{
      font-size: 10px;
    }

    .vs-months{
      color: #666;
      font-size: 9px;
    }
  }
}


.report-block ul.city-list{

  list-style: none;



  li{
    margin-bottom:15px;

    span{
      float: left;
      width:50%;

      &:nth-child(2){
        text-align: right;
      }
    }

    .progress{
      height: 5px;
      clear: both;
      background: $primary;
    }
  }
}


table.monthly-reports-table{

  th{
    width:15%;

    &:nth-child(1){
      width:40%;
    }
  }
}





.report-blocks{

  .report-block.report-block-main-title{


    .report-block-header{
      display: block;
    }

    h2{

      font-size: 35px;
      color: #000000;
      transform: translateY(20px);

      svg{
        width:50px;
        height:  auto;
      }
    }

    @media (max-width: 991px) {
      h2{
        transform: none;
      }
    }

    @media (max-width: 767px) {

      margin-top: -40px;

      h2{
        transform: none;
        font-size: 26px;
        svg{
          width:30px;
        }
      }
    }
  }


}




.page-highlights-wrap-old{

  position: relative;
  float: right;
  width:50%;

  .page-highlights{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 20px;

    .page-highlight-item{
      position: relative;
      padding-left:40px;

      img{
        max-width: 30px;
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
      }

      h4{
        font-size: 26px;
        margin: 0 0 0;
        padding: 0;
        font-weight: 400;
        color: #000;
      }

      span{
        display: block;
        color: #888;
      }


      &:nth-child(1){
        margin-right: 30px;
        padding-right:30px;
        border-right: 1px solid #ededed;
      }
    }


  }

  @media (max-width: 991px) {
    float: none;
    width:100%;

    .page-highlights{

      position: relative;
      margin-bottom:20px;
      bottom: auto;
      right: auto;
    }

  }

}



.page-highlights-wrap{

  .page-highlights {
    display: flex;
    margin-left:-5px;
    margin-right:-5px;
    flex-wrap: wrap;

    .page-highlight-item{

      position: relative;

      margin-bottom:10px;

      padding-left:5px;
      padding-right:5px;

      -webkit-box-flex: 0;
      -webkit-flex: 0 0 18%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 18%;
      flex: 0 0 18%;
      max-width: 18%;
      flex-wrap: wrap;


      &:nth-child(3){
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 28%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
      }


      
      .page-highlight-item-inner{
        position: relative;
        padding:10px 10px 10px 50px;
        border: 1px solid rgba(72, 94, 144, 0.16);
        border-radius: 4px;
        height: 100%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .02);
        transition: all .4s ease;
      }

      img{
        max-width: 30px;
        position: absolute;
        left: 10px;
        top:50%;
        transform: translateY(-50%);
      }

      div.icon{
        max-width: 30px;
        position: absolute;
        left: 10px;
        top:50%;
        transform: translateY(-50%);

        svg{
          width: 100%;
          height: auto;
        }
      }

      h4{
        font-size: 26px;
        margin: 0 0 0;
        padding: 0;
        font-weight: 400;
        color: #000;
        transition: all .4s ease;

        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      .fa{
        font-size: 11px;
        margin-right: 10px;
        display: inline-block;
        transform: translateY(-50%);
        color: #4caf50;
        transition: all .4s ease;

        &.fa-arrow-down{
          color: red;
        }
      }

      span{
        display: block;
        color: #888;
        line-height: 1;
        padding-bottom: 5px;
        transition: all .4s ease;
      }


      

      &.green{
        svg *{
          fill: rgb(109, 189, 70);
        }
      }

      &.purple{
        svg *{
          fill: rgb(112, 59, 227);
        }
      }

      &.blue{
        svg *{
          fill: #45bef6;
        }
      }

      &.light-blue{
        svg *{
          fill: rgb(0, 216, 244);
        }
      }

      

      &.orange{
        svg *{
          fill: #f1b02d;
          
        }
      }


      &:hover{
        .page-highlight-item-inner{
          background-color: #45bef6;
          box-shadow: 0 20px 20px 0 rgba(0,0,0,.1);
          transform: translateY(-5px);

          svg .highcharts-series-group *,
          .icon svg *{
            fill: #FFF!important;
          }

          svg .highcharts-plot-border,
          svg .highcharts-background,
          svg .highcharts-plot-background{
            fill: none!important;
          }

          h4, span, .fa{
            color: #FFF!important;
          }
        }
      }

    }


    &.col4{
      .page-highlight-item{
        flex: 0 0 25%;
        max-width: 25%;

        br{
          display: none;
        }
      }
    }

  }


  @media (max-width: 991px) {

    .page-highlights {

      .page-highlight-item {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        flex-wrap: wrap;


        &:nth-child(3){
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 100%;
          -moz-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &.col4{
        .page-highlight-item{
          flex: 0 0 50%;
          max-width: 50%;
  
          br{
            display: none;
          }
        }
      }
    }
  }
}

.organic-keyword-page-sparkline{
  position: absolute;
  width: auto;
  left:135px;
  height: 50px;
  right: 20px;
  top: 50%;
  margin-top:-25px;



}


.chart-highlights{


  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  align-items: center !important;
  min-width: 400px;

  div{

    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    h4{
      font-size: 26px;
      font-weight: 700;
      margin: 0 0;

      span{
        display: inline-block;
        font-size: 16px;
      }
    }


    &:nth-child(1){
      h4{
        color: #703be3;
      }
    }
    &:nth-child(2){
      h4{
        color: #37c3ff;
      }
    }

    span{

      display: block;

      &.text{

      }

      &.date{
        color: #666;
      }
    }

  }

  @media (max-width: 767px) {

    margin-top: 30px;

    div{

      h4{
        font-size: 22px;
        font-weight: 700;
        margin: 0 0;

        span{
          display: inline-block;
          font-size: 14px;
        }
      }

    }
  }

}



.organic-keywords-filter-wrap{
  position: relative;
  display: inline-block;
  margin:0 5px;

  .selected-date{
    padding: 5px 30px 5px 10px;
    border: 1px solid #e1e5ee;
    border-radius: 3px;
    position: relative;

    i{
      margin-right: 5px;
    }

    &:after{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      content: "\f078";
      color: #afb2ba;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 10px;
    }
    

    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }


  ul.organic-keywords-filter {
    position: absolute;
    background: #FFF;
    list-style: none;
    width: 200px;
    border: 1px solid #e1e5ee;
    border-radius: 4px;
    height: 300px;
    overflow: auto;
    display: none;
    left: 0;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
  
    &.show{
      display: block;
    }
  
    li{
  
      padding: 0;
  
      strong{
        display: block;
        padding: 5px 20px;
      }
  
      .dates{

        padding: 5px 0;
  
        > div{
          padding: 5px 30px;
          border-top: 1px solid #e1e5ee;
          transition: all .4s ease;
          cursor: pointer;

          
          &.cur,
          &:hover{
            background-color: #37c3ff;
            color: #FFF;
          }
  
          &:last-child{
            border-bottom: 1px solid #e1e5ee;
          }

          &.disabled-date{
            color: #919191;

            &:hover{
              background-color: transparent;
              color: #919191;
            }

          }

        }
      }

    
    }
  }


  @media (max-width: 600px) {

    margin: 0 2px;
    
    .selected-date{
      padding: 5px 20px 5px 5px;
      font-size: 10px;
    }

  }
  
}


.report-blocks .report-block .report-block-header.with-highlights{


  .page-description--highlights{
    display: flex;

    >div{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .page-description{
    max-width: 100%;
    padding-right: 20px;

    margin-bottom:0;
    margin-top:20px;

  }

  .page-highlights{
    
    justify-content: flex-end;

    .page-highlight-item{
      flex: 0 0 33.3%;
      max-width: 33.3%;
    }


  }

  @media (max-width: 991px) {

    padding-bottom: 0;

    .page-description--highlights{

      flex-wrap: wrap;
      

      >div{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .page-highlights{

      padding-top: 20px;
    
      .page-highlight-item{
        
      }
    }

    .page-description{
      max-width: 50%;
      padding-right: 0;
    }

  }

  @media (max-width: 767px) {

    .page-highlights{
    
      .page-highlight-item{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .page-description{
      max-width: 100%;
      padding-right: 0;
    }

  }
}


.d6-filter-keywords{
  margin-top: 40px;
  padding: 20px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 5px;

  .form-submit{
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    padding-top: 20px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .save-success-smg,
  .save-error-smg{
    margin-top: 10px;
  }
  
  .save-success-smg{
    color: green;
    
  }

  .save-error-smg{
    color: red;
  }

  .filter-from-to{
    max-width: 400px;
  }

  label{
    margin: 0;

    &.form-lbl{
      margin-top: 10px;
      margin-bottom: 5px;
      font-weight: 600;
    }
  }

  .checkbox-ui-wrap{
    position: relative;
    input{
      position: absolute;
      visibility: hidden;
    }
  }
  span.checkbox-ui{
    display: inline-block;
    height: 24px;
    width: 50px;
    position: relative;
    border-radius: 12px;
    background-color: #6d757d;
    transition: all .4s ease;

    &::before{
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      content: '';
      background: #FFF;
      box-shadow: 0  0 5px 0 rgba(0,0,0,.1);
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      transition: all .4s ease;
    }
  }

  input[type="checkbox"]:checked + span.checkbox-ui{
    background-color: $primary;
    
    &::before{
      left: 29px;
    }
  }
}