@charset "UTF-8";
.project-book-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 46px;
  height: 46px;
  padding: 2px;
  margin-top: -25px;
  margin-left: -25px;
  border-radius: 50%;
  line-height: 48px; }
  .project-book-loader .spinner {
    width: 40px;
    height: 40px;
    position: relative; }
  .project-book-loader .double-bounce1, .project-book-loader .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #37c3ff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }
  .project-book-loader .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

body.project-book-page {
  overflow: hidden; }

header.v2-header {
  padding: 0;
  background: #FFF;
  position: relative;
  z-index: 200;
  border-bottom: 0; }
  header.v2-header .header-container {
    padding: 0;
    border-bottom: 1px solid #ededed; }
  header.v2-header .header-wrap {
    margin-right: 20px;
    transition: all .4s ease; }
    header.v2-header .header-wrap:after {
      content: '';
      position: relative;
      clear: both;
      display: block; }
  header.v2-header .breadcrumbs {
    top: 12px; }
  header.v2-header .logo-wrap {
    position: relative;
    z-index: 2;
    width: 260px;
    padding: 14px 0 13px 20px;
    float: left;
    overflow: hidden;
    transition: all .4s ease; }
    header.v2-header .logo-wrap a.logo {
      width: 260px;
      transition: all .4s ease;
      position: relative; }
      header.v2-header .logo-wrap a.logo:after {
        top: 0;
        width: 40px;
        bottom: 0;
        position: absolute;
        content: '';
        left: -20px;
        transition: all .4s ease;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.34) 1%, rgba(255, 255, 255, 0) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.34) 1%, rgba(255, 255, 255, 0) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255, 255, 255, 0.34) 1%, rgba(255, 255, 255, 0) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#57ffffff', endColorstr='#00ffffff',GradientType=1 );
        /* IE6-9 */ }
      @media (min-width: 1025px) {
        header.v2-header .logo-wrap a.logo:hover:after {
          left: 120%;
          transition: all 2s ease; } }
  header.v2-header .header-container.expand-editor .logo-wrap {
    width: 30px; }
    header.v2-header .header-container.expand-editor .logo-wrap a {
      opacity: 0; }
  header.v2-header .header-container.expand-editor nav {
    padding-left: 40px; }
  header.v2-header .sign-in-link {
    padding-top: 20px; }
  header.v2-header .client-logo {
    display: none; }
  header.v2-header .logged-user {
    color: #adadad;
    padding-top: 22px;
    padding-right: 78px; }
    header.v2-header .logged-user .user-action-popup {
      visibility: hidden;
      display: block !important;
      opacity: 0;
      pointer-events: none;
      transition: all .4s ease;
      -webkit-transform: translateY(30px);
      -moz-transform: translateY(30px);
      -ms-transform: translateY(30px);
      -o-transform: translateY(30px);
      transform: translateY(30px); }
    header.v2-header .logged-user .photo-letter {
      color: #37c3ff; }
    header.v2-header .logged-user a {
      color: #6d6d6d;
      font-weight: 600;
      text-decoration: none;
      transition: all .4s ease; }
      @media (min-width: 1025px) {
        header.v2-header .logged-user a:hover {
          color: #37c3ff; } }
    header.v2-header .logged-user .photo {
      top: 6px;
      right: 20px;
      cursor: pointer;
      background: #FFF;
      transition: all .4s ease; }
      header.v2-header .logged-user .photo img {
        transition: all 1s ease; }
      @media (min-width: 1025px) {
        header.v2-header .logged-user .photo:hover {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); } }
    @media (min-width: 1025px) {
      header.v2-header .logged-user:hover {
        color: #37c3ff; }
        header.v2-header .logged-user:hover .user-actions span.arrow {
          color: #37c3ff; }
        header.v2-header .logged-user:hover .photo img {
          transform: rotateY(360deg); }
        header.v2-header .logged-user:hover:after {
          position: absolute;
          top: 0;
          bottom: -300px;
          right: 0;
          left: 0;
          content: ''; } }
    header.v2-header .logged-user:hover .user-action-popup {
      display: block !important;
      visibility: visible;
      opacity: 1;
      pointer-events: inherit;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    header.v2-header .logged-user .user-actions {
      position: absolute;
      right: 0;
      top: 24px; }
      header.v2-header .logged-user .user-actions span.arrow {
        cursor: pointer;
        color: #b2b2b2;
        transition: all .4s ease; }
        @media (min-width: 1025px) {
          header.v2-header .logged-user .user-actions span.arrow:hover {
            color: #37c3ff; } }
      header.v2-header .logged-user .user-actions .user-action-popup {
        position: absolute;
        top: 43px;
        right: 0;
        background: #FFF;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        z-index: 100;
        width: 150px;
        text-align: center;
        border-radius: 3px;
        display: none; }
        header.v2-header .logged-user .user-actions .user-action-popup.show {
          display: block; }
        header.v2-header .logged-user .user-actions .user-action-popup:after {
          bottom: 100%;
          right: 38px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #FFF;
          border-width: 6px;
          margin-left: -6px; }
        header.v2-header .logged-user .user-actions .user-action-popup ul li {
          list-style: none; }
          header.v2-header .logged-user .user-actions .user-action-popup ul li + li {
            border-top: 1px solid #ededed; }
          header.v2-header .logged-user .user-actions .user-action-popup ul li a {
            display: block;
            padding: 10px 20px;
            text-decoration: none;
            font-weight: normal;
            transition: all .4s ease; }
            @media (min-width: 1025px) {
              header.v2-header .logged-user .user-actions .user-action-popup ul li a:hover {
                background: #37c3ff;
                color: #FFF; } }
  header.v2-header nav {
    padding-left: 250px;
    margin-top: 0;
    margin-right: 220px;
    transition: all .4s ease; }
    header.v2-header nav ul.main-menu {
      text-align: center; }
      header.v2-header nav ul.main-menu li a {
        padding: 23px 0;
        margin: 0 20px;
        color: #000; }
        header.v2-header nav ul.main-menu li a:after {
          height: 2px;
          background: #FFF;
          left: 20px;
          right: 20px;
          position: absolute;
          bottom: 10px;
          content: '';
          transition: all .4s ease;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0); }
        header.v2-header nav ul.main-menu li a.cur, header.v2-header nav ul.main-menu li a:focus, header.v2-header nav ul.main-menu li a.is-active {
          color: #37c3ff; }
          header.v2-header nav ul.main-menu li a.cur:after, header.v2-header nav ul.main-menu li a:focus:after, header.v2-header nav ul.main-menu li a.is-active:after {
            background: #37c3ff;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1); }
        @media (min-width: 1025px) {
          header.v2-header nav ul.main-menu li a:hover {
            color: #37c3ff; }
            header.v2-header nav ul.main-menu li a:hover:after {
              background: #37c3ff;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -ms-transform: scale(1);
              -o-transform: scale(1);
              transform: scale(1); } }
      header.v2-header nav ul.main-menu li ul {
        position: absolute;
        background: #FFF;
        width: 160px;
        border: 1px solid #ededed;
        border-top: 0;
        border-radius: 0 0 5px 5px;
        left: 50%;
        margin-left: -80px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all .4s ease; }
        header.v2-header nav ul.main-menu li ul li a span {
          color: #000;
          transition: all .4s ease; }
        header.v2-header nav ul.main-menu li ul li a:hover span {
          color: #37c3ff; }
        header.v2-header nav ul.main-menu li ul li a:after {
          display: none; }
      header.v2-header nav ul.main-menu li:hover ul {
        pointer-events: inherit;
        opacity: 1;
        visibility: visible; }
      header.v2-header nav ul.main-menu:before {
        display: none; }
    @media (max-width: 991px) {
      header.v2-header nav {
        margin-right: 50px;
        padding-right: 0;
        padding-left: 180px; }
        header.v2-header nav ul.main-menu li a {
          padding: 20px 0;
          margin: 0 15px;
          font-size: 14px; } }

.project-book-page-wrap {
  font-family: acumin-pro, sans-serif; }

.page-wrap {
  padding-bottom: 0; }

.project-book-content-wrap {
  position: relative;
  background: #f4f6fc;
  padding-right: 0;
  transition: all .4s ease; }
  .project-book-content-wrap:after {
    display: block;
    clear: both;
    content: ''; }
  .project-book-content-wrap .mobile-nav-toggle {
    display: none; }
  .project-book-content-wrap .project-book-sidebar {
    width: 260px;
    position: absolute;
    left: 0;
    float: left;
    padding-top: 60px;
    z-index: 222;
    overflow: hidden;
    transition: all .4s ease;
    font-size: 14px;
    padding-bottom: 50px; }
    .project-book-content-wrap .project-book-sidebar .sub-page-title {
      font-weight: 500;
      color: #1a1a1a;
      position: relative;
      padding-left: 22px;
      z-index: 222; }
      .project-book-content-wrap .project-book-sidebar .sub-page-title span.section-title {
        display: block;
        margin: 1px 25px 1px 1px; }
        .project-book-content-wrap .project-book-sidebar .sub-page-title span.section-title.editable {
          outline: 1px solid #37c3ff;
          cursor: text;
          text-overflow: inherit;
          white-space: normal; }
      .project-book-content-wrap .project-book-sidebar .sub-page-title span.action {
        width: 16px;
        height: 16px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        right: 4px;
        top: 0;
        transition: all .4s ease; }
        .project-book-content-wrap .project-book-sidebar .sub-page-title span.action i {
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 8px;
          color: #00a3ef;
          background: #eef1f1;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
          transition: all .4s ease; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .project-book-sidebar .sub-page-title span.action:hover i {
            background: #37c3ff;
            color: #FFF; } }
    .project-book-content-wrap .project-book-sidebar .sidebar-search-bar {
      height: 50px;
      background: #f6f7f8;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: none; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar .sidebar-search-bar-inner {
        transition: all .4s ease; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar i {
        position: absolute;
        top: 20px;
        left: 10px;
        color: #bdbdbd; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar input {
        border: 0;
        height: 50px;
        padding: 0 10px 0 35px;
        width: 100%;
        background: none;
        outline: none !important;
        color: #333; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar ::-webkit-input-placeholder {
        color: #bdbdbd; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar ::-moz-placeholder {
        color: #bdbdbd; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar :-ms-input-placeholder {
        color: #bdbdbd; }
      .project-book-content-wrap .project-book-sidebar .sidebar-search-bar :-moz-placeholder {
        color: #bdbdbd; }
    .project-book-content-wrap .project-book-sidebar .sub-page-item {
      padding: 0 0 0 5px;
      list-style: none; }
      .project-book-content-wrap .project-book-sidebar .sub-page-item:hover .sub-page-title span.action {
        visibility: visible;
        opacity: 1; }
    .project-book-content-wrap .project-book-sidebar .project-book-sidebar-inner {
      overflow-y: auto;
      width: 260px;
      padding-bottom: 10px;
      transition: all .4s ease; }
    .project-book-content-wrap .project-book-sidebar .expand-toggle {
      display: none;
      position: absolute;
      top: 22px;
      right: 0;
      color: #b4b4b4;
      cursor: pointer;
      font-size: 12px;
      z-index: 30; }
      .project-book-content-wrap .project-book-sidebar .expand-toggle .fa-angle-double-left,
      .project-book-content-wrap .project-book-sidebar .expand-toggle .fa-angle-double-right {
        position: absolute;
        right: 10px; }
      .project-book-content-wrap .project-book-sidebar .expand-toggle .fa-angle-double-right {
        display: none; }
      @media (min-width: 1025px) {
        .project-book-content-wrap .project-book-sidebar .expand-toggle:hover {
          color: #37c3ff; } }
    .project-book-content-wrap .project-book-sidebar .project-book-sidebar-nav {
      display: none; }
    .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul {
      position: relative;
      padding: 0;
      margin-left: -5px; }
      .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.ui-sortable-helper {
        opacity: .7; }
      .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.ui-sortable-placeholder {
        opacity: 1 !important;
        visibility: visible !important;
        height: 5px !important; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.ui-sortable-placeholder.mjs-nestedSortable-error {
          display: none !important; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.ui-sortable-placeholder:after {
          position: absolute;
          content: '';
          bottom: 0;
          right: 0;
          left: 0;
          border-bottom: 2px solid #666 !important; }
      .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li {
        position: relative;
        cursor: pointer;
        list-style: none; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.no-id-yet:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          z-index: 20; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.no-id-yet a span.title {
          -webkit-animation: fadeInFadeOut 1s infinite ease;
          -moz-animation: fadeInFadeOut 1s infinite ease;
          -o-animation: fadeInFadeOut 1s infinite ease; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li:focus a {
          padding-right: 25px !important; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li:hover a {
            padding-right: 25px !important; } }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li span.toggle-items {
          z-index: 20;
          position: absolute;
          cursor: pointer;
          width: 20px;
          height: 20px;
          top: 3px;
          right: 2px; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li span.toggle-items:before {
            content: '\f054';
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            color: #b7bdd6;
            font-size: 10px;
            top: 0;
            text-align: center;
            transition: all .4s ease; }
          @media (max-width: 991px) {
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li span.toggle-items {
              display: none !important; } }
          @media (min-width: 1025px) {
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li span.toggle-items:hover:before {
              color: #37c3ff !important; } }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.show-items span.toggle-items:before {
          transform: rotate(90deg); }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action {
          position: absolute;
          font-size: 13px;
          right: 17px;
          top: 3px;
          width: 22px;
          text-align: center;
          height: 18px;
          color: #b7bdd6;
          display: none;
          cursor: pointer;
          z-index: 9;
          transition: all .4s ease; }
          @media (min-width: 1025px) {
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action:hover {
              color: #37c3ff; } }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu {
          position: absolute;
          right: 5px;
          top: 35px;
          width: 160px;
          background: #FFF;
          border-radius: 3px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          z-index: 30;
          display: none; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item + .item {
            border-top: 1px solid #ededed; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item {
            padding: 8px 10px;
            font-size: 14px;
            cursor: pointer; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item i {
              width: 20px;
              font-size: 12px;
              text-align: center;
              display: inline-block;
              color: #94acb7; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item i.fa-pencil-alt {
                color: #94acb7; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item i.fa-trash-alt {
                color: #94acb7; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item i.fa-file {
                color: #94acb7; }
            @media (min-width: 1025px) {
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu .item:hover {
                background: #e6f7ff; } }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .action-menu.show {
            display: block; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a {
          transition: all 0s ease !important;
          position: relative;
          display: block;
          padding: 2px 5px 2px 20px;
          color: #222a33;
          text-decoration: none;
          font-weight: 400;
          font-size: 14px;
          transition: all .4s ease;
          border-left: 3px solid #f4f6fc;
          white-space: nowrap; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.image-icon {
            padding-left: 20px; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.image-icon .page-icon {
              transition: all .4s ease;
              cursor: default;
              position: absolute;
              left: 7px;
              top: 1px; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.image-icon .page-icon img {
                max-width: 15px; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a svg {
            width: 18px; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a svg.folder {
              fill: #333333; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a i {
            color: #94acb7; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a i.fa-file {
              color: #009bd7; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a i.sub-file {
              color: #00beda; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title {
            display: block;
            position: relative;
            outline: none;
            margin-left: 10px;
            padding-bottom: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 0;
            color: #44494e; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title span.title-in {
              position: relative;
              display: inline-block; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title span.title-in:after {
                height: 1px;
                background: #37c3ff;
                position: absolute;
                content: '';
                bottom: -1px;
                left: 0;
                right: 0;
                transition: all .4s ease;
                transform: scale(0); }
            @media (min-width: 1025px) {
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title:hover span.title-in:after {
                transform: scale(1); } }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title.editable {
              outline: 1px solid #37c3ff;
              cursor: text;
              text-overflow: inherit;
              white-space: normal; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a span.title:after {
              content: ' '; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a:after {
            position: absolute;
            width: 6px;
            height: 6px;
            left: -2px;
            top: 11px;
            content: '';
            border-radius: 50%;
            background: #cbcbcb;
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease;
            transform: scale(0); }
          @media (min-width: 1025px) {
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a:hover {
              padding-right: 20px;
              color: #009bd7;
              transition: all 0.4s ease !important; } }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.is-active, .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.active {
            color: #009bd7 !important; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.is-active span.title, .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li a.active span.title {
              color: #009bd7 !important; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul {
          margin-left: 20px;
          display: none;
          margin-bottom: 5px; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li.ui-sortable-placeholder:after {
            border-bottom: 2px solid #37c3ff !important; }
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li {
            list-style: none; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li a {
              padding: 2px 5px 2px 22px;
              font-size: 14px;
              text-transform: inherit;
              color: #6d6d6d;
              margin-left: 0; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li a:before {
                display: none; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li a svg {
                fill: #009bd7; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li span.toggle-items:before {
              transform: rotate(0) !important; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li.show-items span.toggle-items:before {
              transform: rotate(90deg) !important; }
            .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li ul {
              display: none;
              margin-left: 20px;
              margin-bottom: 0; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li ul li .action {
                right: 5px; }
              .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li ul ul {
                display: block; }
                .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul li ul ul ul {
                  display: none !important; }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .text-icon:focus .action {
          display: block; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li .text-icon:hover .action {
            display: block; } }
        .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li.lvl1 > .item-wrap span.title {
          font-weight: 500;
          color: #222a33; }
    .project-book-content-wrap .project-book-sidebar .actions {
      position: absolute;
      bottom: 0;
      padding: 10px;
      width: 260px;
      transition: all .4s ease; }
      .project-book-content-wrap .project-book-sidebar .actions .add-item {
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        color: #37c3ff;
        font-weight: 500;
        transition: all .4s ease;
        position: relative; }
        .project-book-content-wrap .project-book-sidebar .actions .add-item i {
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 8px;
          color: #FFF;
          background: #37c3ff;
          text-align: center;
          border-radius: 50%;
          margin-right: 6px;
          transition: all .4s ease;
          -webkit-transform: translateY(-2px) translateZ(0);
          -moz-transform: translateY(-2px) translateZ(0);
          -ms-transform: translateY(-2px) translateZ(0);
          -o-transform: translateY(-2px) translateZ(0);
          transform: translateY(-2px) translateZ(0); }
        .project-book-content-wrap .project-book-sidebar .actions .add-item span.title-in {
          position: relative; }
          .project-book-content-wrap .project-book-sidebar .actions .add-item span.title-in:after {
            position: absolute;
            content: '';
            bottom: -1px;
            left: 0;
            right: 0;
            height: 1px;
            background: #37c3ff;
            transition: all .4s ease;
            transform: scale(0); }
        @media (min-width: 1025px) {
          .project-book-content-wrap .project-book-sidebar .actions .add-item:hover {
            color: #37c3ff; }
            .project-book-content-wrap .project-book-sidebar .actions .add-item:hover i {
              background: #505050;
              -webkit-transform: translateY(-2px) scale(1.1) translateZ(0);
              -moz-transform: translateY(-2px) scale(1.1) translateZ(0);
              -ms-transform: translateY(-2px) scale(1.1) translateZ(0);
              -o-transform: translateY(-2px) scale(1.1) translateZ(0);
              transform: translateY(-2px) scale(1.1) translateZ(0); }
            .project-book-content-wrap .project-book-sidebar .actions .add-item:hover span.title-in:after {
              transform: scale(1); } }
    .project-book-content-wrap .project-book-sidebar .project-list {
      padding: 20px 10px;
      border-top: 1px solid #ebecf2;
      margin-top: 20px;
      position: relative;
      display: none; }
      .project-book-content-wrap .project-book-sidebar .project-list h4 {
        color: #222a33;
        font-size: 12px;
        text-transform: uppercase; }
      .project-book-content-wrap .project-book-sidebar .project-list ul li {
        display: block;
        padding: 2px 0; }
        .project-book-content-wrap .project-book-sidebar .project-list ul li a {
          color: #44494e;
          text-decoration: none;
          position: relative;
          display: inline-block;
          padding-left: 23px; }
          .project-book-content-wrap .project-book-sidebar .project-list ul li a img {
            max-width: 14px;
            position: absolute;
            margin-right: 3px;
            left: 0;
            top: 4px; }
    .project-book-content-wrap .project-book-sidebar .project-dropdown {
      margin-bottom: 15px;
      margin-top: -45px;
      padding: 0 0 15px;
      border-bottom: 1px solid #ededed;
      position: relative;
      z-index: 230;
      cursor: pointer; }
      .project-book-content-wrap .project-book-sidebar .project-dropdown .project-dropdown-lbl {
        margin-left: 8px;
        font-size: 12px;
        color: #9d9d9d;
        padding-bottom: 5px; }
      .project-book-content-wrap .project-book-sidebar .project-dropdown .current-project {
        position: relative;
        font-weight: 500;
        font-size: 13px;
        color: #222a33;
        transition: all .4s ease;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 2px 2px 2px 33px; }
        .project-book-content-wrap .project-book-sidebar .project-dropdown .current-project:before {
          background-color: #FFF;
          background-image: url("../images/file.svg");
          position: absolute;
          content: '';
          width: 20px;
          height: 20px;
          background-size: 70%;
          left: 7px;
          top: 2px;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 3px;
          opacity: .6; }
        .project-book-content-wrap .project-book-sidebar .project-dropdown .current-project:after {
          right: 0;
          content: '\F054';
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          position: relative;
          display: inline-block;
          margin-left: 5px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: #222a33;
          font-size: 10px;
          -webkit-transform: rotate(90deg) translateX(6px);
          -moz-transform: rotate(90deg) translateX(6px);
          -ms-transform: rotate(90deg) translateX(6px);
          -o-transform: rotate(90deg) translateX(6px);
          transform: rotate(90deg) translateX(6px);
          transition: all .4s ease; }
      .project-book-content-wrap .project-book-sidebar .project-dropdown ul {
        background: #FFF;
        top: 58px;
        left: 6px;
        right: 6px;
        position: absolute;
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transform: translateY(40px); }
        .project-book-content-wrap .project-book-sidebar .project-dropdown ul li {
          list-style: none; }
          .project-book-content-wrap .project-book-sidebar .project-dropdown ul li + li {
            border-top: 1px solid #ededed; }
          .project-book-content-wrap .project-book-sidebar .project-dropdown ul li a {
            padding: 10px;
            display: block;
            font-size: 13px;
            color: #222a33;
            text-decoration: none;
            transition: all .4s ease; }
            .project-book-content-wrap .project-book-sidebar .project-dropdown ul li a img {
              max-width: 16px;
              margin-right: 5px;
              transform: translateY(-2px); }
            @media (min-width: 1025px) {
              .project-book-content-wrap .project-book-sidebar .project-dropdown ul li a:hover {
                color: #37c3ff; } }
      .project-book-content-wrap .project-book-sidebar .project-dropdown:hover .current-project {
        color: #37c3ff; }
        .project-book-content-wrap .project-book-sidebar .project-dropdown:hover .current-project:after {
          color: #37c3ff; }
      .project-book-content-wrap .project-book-sidebar .project-dropdown:hover ul {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0); }
      @media (max-width: 767px) {
        .project-book-content-wrap .project-book-sidebar .project-dropdown {
          position: relative;
          margin-top: 5px;
          margin-bottom: 0;
          padding: 10px 0; } }
  .project-book-content-wrap .project-book-content {
    margin-left: 200px;
    position: relative;
    padding-top: 0;
    z-index: 30;
    transition: all .4s ease; }
    .project-book-content-wrap .project-book-content:after {
      position: absolute;
      content: '';
      background: #FFF;
      box-shadow: 0 20px 57px 0 rgba(0, 0, 0, 0.08), 0 20px 57px 0 rgba(0, 0, 0, 0.06);
      top: 65px;
      left: 60px;
      right: 60px;
      bottom: 0; }
    .project-book-content-wrap .project-book-content .page-title {
      font-weight: 500;
      right: 0;
      background: #f6f7f8;
      top: 0;
      position: absolute;
      left: 0;
      height: 50px;
      padding: 14px 12px 14px 30px;
      display: none; }
      .project-book-content-wrap .project-book-content .page-title .title {
        float: left; }
      .project-book-content-wrap .project-book-content .page-title .toggle-sidebar-mobile {
        display: none; }
      .project-book-content-wrap .project-book-content .page-title .page-actions {
        float: right;
        margin-top: -2px; }
        .project-book-content-wrap .project-book-content .page-title .page-actions button {
          padding: 4px 10px;
          border: 0;
          background: none;
          color: #37c3ff;
          outline: none;
          font-size: 13px;
          transition: all 0.4s ease !important;
          border-radius: 3px; }
          .project-book-content-wrap .project-book-content .page-title .page-actions button i {
            font-size: 10px;
            margin-right: 5px;
            border: 1px solid #37c3ff;
            color: #37c3ff;
            height: 18px;
            width: 18px;
            line-height: 16px;
            border-radius: 50%;
            text-align: center;
            transition: all 0.4s ease !important; }
            .project-book-content-wrap .project-book-content .page-title .page-actions button i.fa-thumbs-up {
              border: 0 !important;
              font-size: 13px; }
          .project-book-content-wrap .project-book-content .page-title .page-actions button.disabled {
            color: #666;
            border: 0 !important;
            background: none !important;
            box-shadow: none !important;
            cursor: default; }
            .project-book-content-wrap .project-book-content .page-title .page-actions button.disabled i {
              color: #666; }
          @media (min-width: 1025px) {
            .project-book-content-wrap .project-book-content .page-title .page-actions button:hover {
              background: #FFF;
              box-shadow: 0 0 0 1px #37c3ff; } }
    .project-book-content-wrap .project-book-content .block-editor {
      position: absolute;
      margin-left: -125px;
      width: 250px;
      left: 50%;
      top: 50%;
      text-align: center;
      z-index: 100; }
      .project-book-content-wrap .project-book-content .block-editor .block-editor-content {
        background: white;
        border-radius: 6px;
        padding: 20px;
        border: 1px solid #ededed;
        font-size: 14px;
        position: absolute;
        margin-left: -125px;
        width: 250px;
        left: 50%;
        top: 50%;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
        .project-book-content-wrap .project-book-content .block-editor .block-editor-content strong {
          text-transform: uppercase; }
    .project-book-content-wrap .project-book-content:before {
      display: none;
      right: 0;
      left: 0;
      bottom: 0;
      top: 50px;
      content: '';
      position: absolute;
      background: #FFF;
      z-index: 22;
      box-shadow: 0 2px 13px -2px rgba(0, 0, 0, 0.2);
      transition: all .4s ease; }
    .project-book-content-wrap .project-book-content .project-book-content-inner {
      position: relative;
      z-index: 23;
      height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      /*
      &::-webkit-scrollbar {
        width: .5em;
        height: .5em
      }

      &::-webkit-scrollbar-button {
        background: #ccc;
      }
      &::-webkit-scrollbar-thumb{
        background: #dedede;
        border-radius: 10px;
      }​
      &::-webkit-scrollbar-track-piece {
        background: #888;
      }
      */ }
  .project-book-content-wrap .submit {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7fbfd; }
  .project-book-content-wrap.expand-editor:before {
    left: 30px; }
  .project-book-content-wrap.expand-editor .project-book-sidebar {
    width: 30px; }
    .project-book-content-wrap.expand-editor .project-book-sidebar .actions,
    .project-book-content-wrap.expand-editor .project-book-sidebar .sidebar-search-bar-inner,
    .project-book-content-wrap.expand-editor .project-book-sidebar .project-book-sidebar-inner {
      opacity: 0;
      pointer-events: none; }
    .project-book-content-wrap.expand-editor .project-book-sidebar .expand-toggle {
      left: 0;
      bottom: 0; }
      .project-book-content-wrap.expand-editor .project-book-sidebar .expand-toggle .fa-angle-double-right {
        display: block; }
      .project-book-content-wrap.expand-editor .project-book-sidebar .expand-toggle .fa-angle-double-left {
        display: none; }
  .project-book-content-wrap.expand-editor .project-book-content {
    margin-left: 30px; }
    .project-book-content-wrap.expand-editor .project-book-content .tiny-mce-editor .tox .tox-toolbar-wrap.fixed {
      left: 30px !important; }
  .project-book-content-wrap .project-book-editor-footer-actions {
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 60px;
    margin-left: 50px;
    margin-right: 50px;
    min-width: 0 !important; }
    .project-book-content-wrap .project-book-editor-footer-actions:after {
      display: block;
      width: 100%;
      position: relative;
      clear: both;
      content: ''; }
  .project-book-content-wrap .project-book-attachments {
    padding: 20px 0;
    width: 100%; }
    .project-book-content-wrap .project-book-attachments .attach-file-btn {
      display: inline-block;
      color: #37c3ff;
      font-weight: bold;
      font-size: 13px;
      cursor: pointer;
      outline: none;
      transition: all .4s ease;
      padding: 7px 15px;
      border: 1px solid #ededed;
      border-radius: 3px; }
      .project-book-content-wrap .project-book-attachments .attach-file-btn p {
        margin-bottom: 0; }
      .project-book-content-wrap .project-book-attachments .attach-file-btn i {
        font-size: 11px; }
      @media (min-width: 1025px) {
        .project-book-content-wrap .project-book-attachments .attach-file-btn:hover {
          border-color: #37c3ff;
          box-shadow: 0 0 0 1px #37c3ff; } }
    .project-book-content-wrap .project-book-attachments .fa-spinner {
      width: 20px;
      text-align: center;
      font-size: 12px !important;
      line-height: 20px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      margin: 0;
      top: 5px; }
    .project-book-content-wrap .project-book-attachments .delete-attachment {
      display: inline-block;
      color: #dedede;
      font-weight: bold;
      background: #FFF;
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 17px;
      font-size: 12px;
      border: 1px solid #ededed;
      border-radius: 50%;
      cursor: pointer;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 4; }
      @media (min-width: 1025px) {
        .project-book-content-wrap .project-book-attachments .delete-attachment:hover {
          border: 1px solid #da493f;
          background: #da493f;
          color: #FFF; } }
    .project-book-content-wrap .project-book-attachments .attachments {
      padding-top: 10px; }
    .project-book-content-wrap .project-book-attachments .attachment-item {
      font-size: 14px;
      width: 100px;
      height: 180px;
      float: left;
      margin-right: 10px;
      overflow-x: hidden;
      position: relative; }
      .project-book-content-wrap .project-book-attachments .attachment-item a {
        color: #333;
        transition: all .4s ease;
        text-decoration: none; }
        .project-book-content-wrap .project-book-attachments .attachment-item a .icon {
          width: 100px;
          height: 100px;
          display: inline-block;
          border: 1px solid #ededed;
          border-radius: 3px;
          background-color: #FFF;
          background-size: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../images/file-icons/file.png");
          transition: all .4s ease; }
        .project-book-content-wrap .project-book-attachments .attachment-item a .name {
          overflow: hidden;
          font-size: 11px;
          display: inline-block;
          word-break: break-all;
          line-height: 1.2; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .project-book-attachments .attachment-item a:hover {
            color: #37c3ff; }
            .project-book-content-wrap .project-book-attachments .attachment-item a:hover .icon {
              background-color: #fafafa; } }
      .project-book-content-wrap .project-book-attachments .attachment-item .fa-paperclip {
        font-size: 12px;
        display: inline-block;
        margin-right: 10px;
        color: #666;
        display: none; }
      .project-book-content-wrap .project-book-attachments .attachment-item .loading-item {
        height: 100px;
        display: inline-block;
        border: 1px solid #ededed;
        font-size: 11px;
        padding: 30px 10px;
        position: absolute;
        right: 0;
        width: 100%;
        border-radius: 3px; }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-ai a .icon {
        background-image: url("../images/file-icons/illustrator.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-pdf a .icon {
        background-image: url("../images/file-icons/pdf.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-zip a .icon {
        background-image: url("../images/file-icons/zip.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-doc a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-docx a .icon {
        background-image: url("../images/file-icons/word.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-xls a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-csv a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-xlsx a .icon {
        background-image: url("../images/file-icons/excel.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-ppt a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-pptx a .icon {
        background-image: url("../images/file-icons/powerpoint.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-psd a .icon {
        background-image: url("../images/file-icons/photoshop.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-mp3 a .icon {
        background-image: url("../images/file-icons/mp3.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-mp4 a .icon {
        background-image: url("../images/file-icons/video.svg"); }
      .project-book-content-wrap .project-book-attachments .attachment-item.ext-jpg a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-jpeg a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-png a .icon, .project-book-content-wrap .project-book-attachments .attachment-item.ext-gif a .icon {
        background-image: url("../images/file-icons/image.svg"); }
  .project-book-content-wrap .save-project-book-page {
    background: #FFF;
    padding: 10px 30px 10px;
    position: fixed;
    bottom: 0;
    right: 60px;
    text-align: right;
    left: 260px;
    z-index: 10;
    border-top: 1px solid #ededed; }
    .project-book-content-wrap .save-project-book-page .btn {
      padding: 5px 20px; }
    .project-book-content-wrap .save-project-book-page .left-button {
      background: none;
      border: 0;
      float: left;
      padding: 0;
      margin-right: 15px;
      color: #797979;
      transition: all .4s ease;
      outline: none;
      font-size: 14px;
      transform: translateY(5px); }
      @media (min-width: 1025px) {
        .project-book-content-wrap .save-project-book-page .left-button:hover {
          color: #058ce4; } }
      .project-book-content-wrap .save-project-book-page .left-button.with-text {
        background: -moz-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
        background: -webkit-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
        background: linear-gradient(to right, #7c81f4 0%, #03ccd9 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c81f4', endColorstr='#03ccd9',GradientType=1 );
        color: #FFF;
        padding: 0 0 0 40px;
        border-radius: 3px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        transform: translateY(2px); }
        .project-book-content-wrap .save-project-book-page .left-button.with-text:before {
          position: absolute;
          content: '';
          width: 30px;
          top: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.1);
          transition: all .4s ease; }
        .project-book-content-wrap .save-project-book-page .left-button.with-text .fas {
          position: absolute;
          left: 8px;
          top: 9px; }
        .project-book-content-wrap .save-project-book-page .left-button.with-text span.s1 {
          position: relative;
          transition: all .4s ease;
          display: block;
          padding: 5px 12px 5px 0; }
          .project-book-content-wrap .save-project-book-page .left-button.with-text span.s1 span.s2 {
            padding: 5px 12px 5px 0;
            top: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            color: #FFF; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .save-project-book-page .left-button.with-text:hover {
            background: #03ccd9;
            background: -moz-linear-gradient(left, #03ccd9 0%, #7c81f4 100%);
            background: -webkit-linear-gradient(left, #03ccd9 0%, #7c81f4 100%);
            background: linear-gradient(to right, #03ccd9 0%, #7c81f4 100%); }
            .project-book-content-wrap .save-project-book-page .left-button.with-text:hover span.s1 {
              transform: translateY(-100%); }
            .project-book-content-wrap .save-project-book-page .left-button.with-text:hover:before {
              background: rgba(0, 0, 0, 0.2); } }
  .project-book-content-wrap .bottom-scroller-visible .save-project-book-page {
    bottom: 15px; }
  .project-book-content-wrap .page-btn-popup {
    width: 250px;
    padding: 50px 0 20px 20px;
    position: absolute;
    bottom: 60px;
    left: 0;
    background: #FFF;
    z-index: 2;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-size: 13px; }
    .project-book-content-wrap .page-btn-popup .page-btn-popup-inner {
      max-height: 138px;
      overflow: auto; }
    .project-book-content-wrap .page-btn-popup.revisions-popup {
      left: 0; }
    .project-book-content-wrap .page-btn-popup .close {
      position: absolute;
      top: 4px;
      z-index: 22;
      color: #FFF;
      opacity: 1;
      text-shadow: none;
      right: 10px;
      font-weight: normal;
      cursor: pointer; }
    .project-book-content-wrap .page-btn-popup.settings-popup {
      width: 280px;
      left: 30px;
      text-align: left;
      padding-right: 20px; }
      .project-book-content-wrap .page-btn-popup.settings-popup .switch-button {
        padding: 0;
        margin: 0 0 10px;
        border-bottom: 1px solid #f6f6f6; }
        .project-book-content-wrap .page-btn-popup.settings-popup .switch-button span {
          color: #222;
          text-transform: none; }
        .project-book-content-wrap .page-btn-popup.settings-popup .switch-button .switch-ui {
          float: right; }
    .project-book-content-wrap .page-btn-popup .text-left {
      text-align: left; }
    .project-book-content-wrap .page-btn-popup:after {
      top: 100%;
      left: 40px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #FFF;
      border-width: 6px;
      margin-left: -6px; }
    .project-book-content-wrap .page-btn-popup h3 {
      font-size: 14px;
      text-align: left;
      padding: 10px 20px 10px 20px;
      color: #FFF;
      background: #37c3ff;
      text-transform: uppercase;
      font-weight: 600;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 4;
      margin: 0; }
    .project-book-content-wrap .page-btn-popup ul {
      list-style: none;
      text-align: left; }
      .project-book-content-wrap .page-btn-popup ul li {
        cursor: pointer;
        transition: all .4s ease; }
        .project-book-content-wrap .page-btn-popup ul li i {
          opacity: .3;
          color: #666;
          font-size: 14px;
          display: inline-block;
          margin-right: 5px; }
        @media (min-width: 1025px) {
          .project-book-content-wrap .page-btn-popup ul li:hover {
            color: #37c3ff; }
            .project-book-content-wrap .page-btn-popup ul li:hover i {
              color: #37c3ff;
              opacity: 1; } }

.tiny-mce-editor {
  margin: 0 60px 0 60px;
  overflow: hidden; }
  .tiny-mce-editor .tiny-mce-editor-inner {
    min-height: 500px;
    margin-left: 0;
    margin-right: 0; }
  .tiny-mce-editor .tox-tinymce {
    border: 0;
    width: 100% !important;
    min-width: 100% !important;
    padding: 80px 0 60px 0; }
    @media (max-width: 767px) {
      .tiny-mce-editor .tox-tinymce {
        padding-top: 0; } }
  .tiny-mce-editor .tox-statusbar {
    display: none !important; }
  .tiny-mce-editor .tox-editor-container {
    overflow: visible; }
  .tiny-mce-editor .tox .tox-tbtn img,
  .tiny-mce-editor .tox .tox-tbtn {
    transition: all .4s ease; }
  .tiny-mce-editor .tox .tox-tbtn--disabled img,
  .tiny-mce-editor .tox .tox-tbtn--disabled:hover img,
  .tiny-mce-editor .tox .tox-tbtn:disabled img,
  .tiny-mce-editor .tox .tox-tbtn:disabled:hover img {
    opacity: .5; }
  .tiny-mce-editor .tox .tox-sidebar-wrap {
    background: #FFF;
    padding: 30px 30px 30px;
    position: relative; }
  .tiny-mce-editor .tox .tox-toolbar-wrap.fixed {
    position: fixed;
    right: 60px;
    left: 260px;
    height: 65px;
    transition: all .4s ease;
    z-index: 100;
    background: #f4f6fc;
    border-bottom: 4px solid #37c3ff;
    padding-top: 5px;
    top: 111px; }
    .tiny-mce-editor .tox .tox-toolbar-wrap.fixed:after {
      left: 0;
      right: 0;
      height: 10px;
      background: #FFF;
      bottom: -11px;
      position: absolute; }
  .tiny-mce-editor .tox .tox-editor-header {
    width: 100%;
    background: none;
    padding: 8px 0;
    margin-left: 20px;
    transform: scale(0.85);
    transform-origin: 0;
    border: 0; }
    @media (max-width: 991px) {
      .tiny-mce-editor .tox .tox-editor-header {
        width: 200%; } }
  .tiny-mce-editor .tox .tox-toolbar-overlord {
    background-color: transparent !important; }
    .tiny-mce-editor .tox .tox-toolbar-overlord .tox-toolbar__primary {
      background: none !important; }
  .tiny-mce-editor .tox .tox-toolbar {
    background: none; }
  .tiny-mce-editor .tox .tox-tinymce {
    border-color: #FFF; }
  .tiny-mce-editor .tox .tox-edit-area {
    border-top-color: #FFF; }
    .tiny-mce-editor .tox .tox-edit-area iframe::-webkit-scrollbar {
      display: none; }
  .tiny-mce-editor .tox .tox-statusbar {
    border-color: #FFF; }
  .tiny-mce-editor.read-only-editor .tox .tox-editor-header {
    opacity: .2;
    position: relative; }
    .tiny-mce-editor.read-only-editor .tox .tox-editor-header:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 10; }
  @media (max-width: 767px) {
    .tiny-mce-editor .tox .tox-sidebar-wrap {
      padding: 0 !important; }
    .tiny-mce-editor .tox .tox-toolbar-wrap {
      overflow: auto;
      display: none; }
    .tiny-mce-editor .tox .tox-editor-header {
      width: 950px;
      border: 0; } }

.chat-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  font-size: 14px;
  display: none; }
  .chat-box.open {
    display: block; }
  .chat-box .close {
    position: absolute;
    width: 26px;
    height: 26px;
    background: #c9c9c9;
    font-size: 16px;
    text-align: center;
    padding: 0;
    font-family: Times;
    font-weight: normal;
    border-radius: 50%;
    opacity: 1;
    color: #FFF;
    text-shadow: none;
    transition: all .4s ease;
    outline: none;
    top: -15px;
    right: -10px;
    box-shadow: 0 0 0 3px #FFF;
    z-index: 10; }
    @media (min-width: 1025px) {
      .chat-box .close:hover {
        background: #37c3ff;
        opacity: 1 !important;
        box-shadow: 0 0 0 3px #FFF; } }
  .chat-box .alert {
    margin-top: 10px; }
  .chat-box .chat-box-content {
    background: #FFF;
    padding: 30px;
    width: 400px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.2); }
    .chat-box .chat-box-content textarea {
      resize: none;
      height: 100px; }
    .chat-box .chat-box-content .form-label {
      font-weight: bold;
      text-transform: uppercase; }
  .chat-box .questions-thread {
    max-height: 500px;
    overflow: auto;
    margin-bottom: 20px;
    margin-right: -30px;
    margin-left: -30px;
    margin-top: -30px;
    padding-top: 20px;
    padding-bottom: 10px;
    background: #f0f4fa;
    border-bottom: 1px solid #ededed; }
    .chat-box .questions-thread .q-t-item {
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      background: #FFF;
      padding: 20px;
      margin: 0 20px;
      margin-bottom: 10px; }
      .chat-box .questions-thread .q-t-item .user {
        font-weight: bold; }
      .chat-box .questions-thread .q-t-item .date {
        font-size: 12px;
        color: #939393; }
  @media (max-width: 600px) {
    .chat-box {
      left: 20px; }
      .chat-box .chat-box-content {
        width: 100%; }
      .chat-box .questions-thread {
        max-height: 350px; } }

.upload-mockup-wrap {
  min-height: 500px;
  background: #FFF;
  margin-top: 40px;
  position: relative; }
  .upload-mockup-wrap .upload-field {
    position: absolute;
    width: 200px;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    color: #b4b4b4;
    cursor: pointer;
    transition: all .4s ease; }
    .upload-mockup-wrap .upload-field span {
      display: block; }
    .upload-mockup-wrap .upload-field:hover {
      color: #37c3ff; }

.design-mockup-page {
  padding-bottom: 30px; }
  .design-mockup-page .project-book-content {
    padding-top: 0; }
    .design-mockup-page .project-book-content:before {
      top: 0; }
  .design-mockup-page .design-mockup-wrap-outer {
    position: relative; }
  .design-mockup-page .design-mockup-wrap {
    padding-top: 50px;
    position: relative;
    background: #f6f7f8; }
    .design-mockup-page .design-mockup-wrap img.mockup-image {
      cursor: crosshair;
      width: 100%;
      opacity: .4;
      transition: all .4s ease; }
    .design-mockup-page .design-mockup-wrap .comment-marker {
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 50%;
      z-index: 10;
      background: #FFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
      border: 3px solid #37c3ff;
      transition: all .4s ease;
      cursor: pointer;
      opacity: 0;
      visibility: hidden; }
      .design-mockup-page .design-mockup-wrap .comment-marker.new {
        -webkit-transform: scale(2);
        -moz-transform: scale(2);
        -ms-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
        background: #37c3ff;
        border: 3px solid #FFF; }
    .design-mockup-page .design-mockup-wrap.mockup-image-loaded .load-bar {
      display: none; }
    .design-mockup-page .design-mockup-wrap.mockup-image-loaded img.mockup-image {
      opacity: 1;
      visibility: visible; }
    .design-mockup-page .design-mockup-wrap.mockup-image-loaded .comment-marker {
      opacity: 1;
      visibility: visible; }
  .design-mockup-page .mockup-content-wrap {
    background: #FFF;
    min-height: 700px; }
  .design-mockup-page .comment-viewer {
    position: absolute;
    background: #FFF;
    padding: 30px 30px;
    border-radius: 6px;
    top: 0;
    left: 0;
    width: 320px;
    font-size: 14px;
    margin-left: -150px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    z-index: 50; }
    .design-mockup-page .comment-viewer .close {
      position: absolute;
      top: 5px;
      right: 8px;
      cursor: pointer;
      color: #898989; }
    .design-mockup-page .comment-viewer .comment-date {
      color: #666; }
    .design-mockup-page .comment-viewer .comment-content {
      max-height: 350px;
      overflow: auto; }
    .design-mockup-page .comment-viewer.bottom {
      margin-top: 30px; }
      .design-mockup-page .comment-viewer.bottom:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #FFFFFF;
        border-width: 6px;
        margin-left: -6px; }
    .design-mockup-page .comment-viewer.top {
      margin-top: -10px; }
      .design-mockup-page .comment-viewer.top:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #FFFFFF;
        border-width: 6px;
        margin-left: -6px; }
    .design-mockup-page .comment-viewer.left {
      margin-top: 10px; }
      .design-mockup-page .comment-viewer.left:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #FFFFFF;
        border-width: 6px;
        margin-top: -6px; }
    .design-mockup-page .comment-viewer.left_bottom {
      margin-top: 35px; }
      .design-mockup-page .comment-viewer.left_bottom:after {
        left: 100%;
        bottom: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #FFFFFF;
        border-width: 6px;
        margin-top: -6px; }
    .design-mockup-page .comment-viewer.right {
      margin-top: 10px; }
      .design-mockup-page .comment-viewer.right:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #FFFFFF;
        border-width: 6px;
        margin-top: -6px; }
    .design-mockup-page .comment-viewer.right_bottom {
      margin-top: 35px; }
      .design-mockup-page .comment-viewer.right_bottom:after {
        right: 100%;
        bottom: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #FFFFFF;
        border-width: 6px;
        margin-top: -6px; }
  .design-mockup-page .approve-mockup-submit {
    padding: 20px;
    text-align: center; }
    .design-mockup-page .approve-mockup-submit button {
      margin-top: 0; }
    .design-mockup-page .approve-mockup-submit .approved-label {
      font-weight: 500;
      color: #37c3ff; }
  .design-mockup-page .project-book-sidebar .project-book-sidebar-inner {
    transition: all .4s ease; }
  .design-mockup-page .project-book-sidebar .expand-toggle {
    top: 38px; }
  .design-mockup-page .project-book-sidebarxxxx .project-book-sidebar-inner {
    transition: all .4s ease; }
  .design-mockup-page .project-book-sidebarxxxx ul li a {
    border: 0 !important;
    color: #787878 !important; }
    .design-mockup-page .project-book-sidebarxxxx ul li a svg {
      fill: #787878 !important; }
    .design-mockup-page .project-book-sidebarxxxx ul li a:hover {
      background: none;
      color: #FFF !important; }
  .design-mockup-page .project-book-sidebarxxxx ul li a.is-active {
    background: none;
    color: #37c3ff !important; }
  .design-mockup-page.expand-editor .project-book-sidebar-inner {
    opacity: 0; }

#addCommentModal textarea {
  height: 150px; }

body.project-book-design-page .modal-backdrop {
  display: none !important; }

.switch-button {
  padding: 0 10px 10px 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  vertical-align: top;
  color: #adadad;
  position: relative; }
  .switch-button span {
    vertical-align: top;
    display: inline-block;
    height: 26px;
    line-height: 22px; }
  .switch-button .switch-ui {
    display: inline-block;
    border-radius: 30px;
    background: #dedede;
    width: 40px;
    height: 22px;
    margin-left: 10px;
    position: relative;
    cursor: pointer; }
    .switch-button .switch-ui:before {
      width: 18px;
      height: 18px;
      background: #FFF;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      content: '';
      transition: all .4s ease; }
    .switch-button .switch-ui.on {
      background: #37c3ff; }
      .switch-button .switch-ui.on:before {
        left: 20px; }

.icons-selector {
  top: 0;
  position: absolute;
  padding: 10px;
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  z-index: 70;
  display: none;
  left: 10px;
  right: 10px; }
  .icons-selector .sel-close {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 22px;
    height: 22px;
    background: #c9c9c9;
    border-radius: 50%;
    display: block;
    font-weight: normal;
    font-size: 14px;
    opacity: 1;
    text-align: center;
    line-height: 15px;
    border: 2px solid #FFF;
    color: #FFF;
    text-shadow: none;
    transition: all .4s ease; }
    .icons-selector .sel-close:hover {
      opacity: 1;
      background: #37c3ff; }
  .icons-selector.show {
    display: block; }
  .icons-selector .icons-selector-inner {
    overflow: auto;
    max-height: 200px; }
  .icons-selector ul li {
    display: inline-block; }
    .icons-selector ul li img {
      max-width: 20px;
      margin: 2px;
      transition: all .4s ease; }
      @media (min-width: 1025px) {
        .icons-selector ul li img:hover {
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -ms-transform: scale(1.3);
          -o-transform: scale(1.3);
          transform: scale(1.3); } }

@media (max-width: 991px) {
  body.project-book-page {
    overflow: auto; }
  header.v2-header .logo-wrap {
    border: 0;
    width: 180px; }
  .project-book-content-wrap {
    padding-bottom: 0; }
    .project-book-content-wrap .mobile-nav-toggle {
      display: block;
      position: relative;
      padding: 10px 50px 10px 20px;
      border-bottom: 1px solid #ededed;
      font-weight: 500; }
      .project-book-content-wrap .mobile-nav-toggle .toggle-sidebar-mobile {
        position: absolute;
        right: 20px;
        font-size: 12px;
        border: 1px solid #ededed;
        padding: 3px 10px;
        border-radius: 3px;
        background: #fafafa;
        color: #b0b0b0; }
        .project-book-content-wrap .mobile-nav-toggle .toggle-sidebar-mobile.active {
          background: #37c3ff;
          color: #FFF;
          border-color: #37c3ff; } }
      @media (max-width: 991px) and (min-width: 1025px) {
        .project-book-content-wrap .mobile-nav-toggle .toggle-sidebar-mobile:hover {
          background: #37c3ff;
          color: #FFF;
          border-color: #37c3ff; } }

@media (max-width: 991px) {
    .project-book-content-wrap .project-book-sidebar {
      float: none;
      width: auto;
      position: fixed;
      z-index: 220;
      background: #FFF;
      box-shadow: none;
      left: 0;
      right: 0;
      top: 116px;
      border: 0;
      bottom: 0;
      overflow: auto;
      border-radius: 3px;
      display: none;
      padding: 0 10px 0; }
      .project-book-content-wrap .project-book-sidebar.show {
        display: block; }
        .project-book-content-wrap .project-book-sidebar.show:before {
          z-index: 1;
          position: absolute;
          content: '';
          background: rgba(255, 255, 255, 0.7);
          left: 0;
          right: 0;
          top: -120px;
          height: 75px; }
      .project-book-content-wrap .project-book-sidebar:after {
        bottom: 100%;
        left: 25px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(246, 247, 248, 0);
        border-bottom-color: #f6f7f8;
        border-width: 6px;
        margin-left: -6px; }
      .project-book-content-wrap .project-book-sidebar .expand-toggle {
        display: none; }
      .project-book-content-wrap .project-book-sidebar .actions {
        display: none !important; }
      .project-book-content-wrap .project-book-sidebar .project-dropdown {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0;
        margin-bottom: 0; }
      .project-book-content-wrap .project-book-sidebar .project-book-sidebar-inner {
        background: #f4f6fc;
        height: auto !important;
        width: auto;
        margin-left: -10px;
        margin-right: -10px;
        overflow: auto;
        padding: 20px 10px; }
    .project-book-content-wrap .project-book-content {
      margin-left: 0;
      padding-top: 0; }
      .project-book-content-wrap .project-book-content .project-book-content-inner {
        height: auto !important;
        padding: 0;
        /*overflow: visible;*/
        padding-bottom: 40px; }
      .project-book-content-wrap .project-book-content .page-title {
        padding: 15px 20px; }
        .project-book-content-wrap .project-book-content .page-title .title {
          font-size: 14px;
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 30px; }
        .project-book-content-wrap .project-book-content .page-title .toggle-sidebar-mobile {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          font-size: 12px;
          z-index: 4;
          right: 110px;
          cursor: pointer; }
          .project-book-content-wrap .project-book-content .page-title .toggle-sidebar-mobile i {
            left: 20px;
            top: 16px;
            font-size: 11px;
            position: absolute;
            color: #999999;
            background: #FFF;
            border: 1px solid #dedede;
            padding: 2px 4px;
            height: 20px;
            line-height: 16px;
            border-radius: 3px;
            display: inline-block;
            transition: all .4s ease; } }
        @media (max-width: 991px) and (min-width: 1025px) {
          .project-book-content-wrap .project-book-content .page-title .toggle-sidebar-mobile:hover i {
            color: #37c3ff;
            border-color: #37c3ff; } }

@media (max-width: 991px) {
        .project-book-content-wrap .project-book-content .page-title .page-actions {
          margin-right: 0px;
          margin-top: -4px; }
          .project-book-content-wrap .project-book-content .page-title .page-actions button {
            background: #FFF;
            border: 1px solid #37c3ff;
            height: 30px;
            margin-left: 2px; }
            .project-book-content-wrap .project-book-content .page-title .page-actions button i {
              margin: 0; }
              .project-book-content-wrap .project-book-content .page-title .page-actions button i.fa-question {
                border: 0;
                font-size: 13px; }
            .project-book-content-wrap .project-book-content .page-title .page-actions button span {
              display: none; }
    .project-book-content-wrap .project-book-attachments {
      width: 100%;
      padding: 20px 0; }
    .project-book-content-wrap .switch-button {
      position: relative; }
  .tiny-mce-editor {
    margin: 0; }
    .tiny-mce-editor .tox .tox-sidebar-wrap {
      padding: 20px; }
    .tiny-mce-editor .tox .tox-toolbar-wrap.fixed {
      top: auto !important;
      left: auto !important;
      right: 0 !important;
      position: relative !important; }
  .tiny-mce-editor .tox-tinymce {
    padding-top: 0; }
  .tiny-mce-editor .tiny-mce-editor-inner {
    position: relative; }
    .tiny-mce-editor .tiny-mce-editor-inner iframe {
      background-color: #fff;
      border: 0;
      height: 100%;
      position: relative;
      width: 100%;
      right: auto;
      left: auto; }
  .tinymce-mobile-outer-container .tinymce-mobile-editor-socket {
    margin: 0;
    padding: 0 !important;
    position: relative !important;
    bottom: auto !important;
    top: auto !important;
    height: auto !important;
    min-height: 400px !important; }
  body .tinymce-mobile-outer-container:not(.tinymce-mobile-fullscreen-maximized) .tinymce-mobile-editor-socket {
    height: auto !important; }
  .project-book-content-wrap {
    background: #FFF; }
    .project-book-content-wrap .project-book-editor-footer-actions {
      margin: 0;
      padding: 10px 0;
      display: none; }
    .project-book-content-wrap .project-book-content:after {
      display: none; }
  .project-book-content-wrap .project-book-sidebar ul#sidebar-pages ul li ul {
    display: block !important; } }

.tinymce-mobile-outer-container .tinymce-mobile-editor-socket {
  padding: 20px 20px 50px 20px;
  width: auto !important;
  right: 0;
  left: 0;
  min-height: 500px; }

.tinymce-mobile-outer-container .tinymce-mobile-disabled-mask {
  background: rgba(51, 51, 51, 0.1) !important;
  display: none !important; }

.tinymce-mobile-outer-container:not(.tinymce-mobile-fullscreen-maximized) .tinymce-mobile-editor-socket {
  height: 100% !important; }

.view-only-on-mobile {
  position: fixed;
  z-index: 100;
  padding: 6px;
  bottom: 0;
  right: 0;
  left: 0;
  background: #FFF;
  border-top: 1px solid #ededed;
  font-size: 12px;
  text-align: center;
  color: #666; }

.tinymce-mobile-outer-container .tinymce-mobile-editor-socket {
  padding-bottom: 0 !important; }

.tox .tox-dialog {
  border-width: 0 !important; }

.tox .tox-dialog__header {
  padding: 8px 16px 8px 16px !important;
  background-color: #37c3ff !important;
  color: #FFF !important; }

body.hide-editor-toolbar .project-book-content-inner .tox-editor-container .tox-toolbar-wrap .tox-editor-header {
  display: none !important; }

/*
 *
 * Scroll body on mobile
 */
@media (max-width: 767px) {
  body,
  html {
    overflow: auto !important; }
  body {
    padding-top: 130px; }
  body.project-book-page {
    padding-top: 130px; }
    body.project-book-page .mobile-nav-toggle {
      position: fixed;
      top: 71px;
      left: 0;
      right: 0;
      z-index: 99;
      background: #FFF; }
  header.v2-header {
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 99; }
  body .project-book-content-inner,
  body main {
    height: auto !important; } }

.content-outside-editor {
  padding: 0 50px; }
  .content-outside-editor iframe {
    width: 100%;
    height: 500px; }

.custom-page-wrap {
  margin-left: 200px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding-left: 60px;
  padding-right: 30px;
  padding-top: 30px; }
  .custom-page-wrap .custom-page-wrap-inner {
    position: relative;
    z-index: 3;
    background: #FFF;
    padding: 30px;
    border-top: 4px solid #37c3ff;
    box-shadow: 0 20px 57px 0 rgba(0, 0, 0, 0.08), 0 20px 57px 0 rgba(0, 0, 0, 0.06); }
  .custom-page-wrap .table-template iframe {
    width: 100%;
    background: none;
    min-height: 300px;
    background-color: #FFF; }
  @media (max-width: 767px) {
    .custom-page-wrap {
      margin-left: 0;
      margin-right: 0;
      padding: 0 0;
      height: auto;
      overflow-y: visible; }
      .custom-page-wrap .custom-page-wrap-inner {
        padding: 10px; } }

#spreadsheet-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2222;
  padding: 0;
  background: #FFF;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;
  pointer-events: none;
  display: none; }
  #spreadsheet-full-screen.show {
    display: block;
    pointer-events: inherit;
    opacity: 1;
    visibility: visible; }
  #spreadsheet-full-screen iframe {
    width: 100%;
    height: 100%;
    border: 0; }

.head-notice {
  overflow: hidden;
  transition: all 1s ease 0s;
  max-height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .head-notice div {
    width: 100%; }
  @media (max-width: 767px) {
    .head-notice {
      height: 40px;
      position: fixed;
      top: 0;
      z-index: 222;
      height: 60px; } }
